import { NavLink } from "react-router-dom";

export default function NavigationBar() {
  const tabs = [
    {
      title: "Kanban",
      path: "/kanban"
    },  
    {
      title: "Calendar",
      path: "/calendar",
      active: true
    }  
  ];

  return (
    <div className="tabs mt-3 mb-2 d-flex gap-3 px-4">
      <div
        className="py-2 px-4 text-white rounded-2 text-center"
        style={{ backgroundColor: "#ED4C16" }}
      >
        {44}
      </div>
      <div className="tabs-header d-flex align-content-center gap-5 bg-white py-2 px-4 rounded-2">
        {tabs.map((tab) => (
          <NavLink className={tab.active?"tab-active tab-t":"tab-t"} key={tab.path}  to={"/app" + tab.path}>{tab.title}</NavLink>
        ))}
      </div>
    </div>
  );
}
