import React from "react";
import Loadable from "react-loadable";

// rct page loader
import RctPageLoader from "../RctPageLoader/RctPageLoader";

const AsyncHomeComponent = Loadable({
  loader: () => import("../../routes/home"),
  loading: () => <RctPageLoader />,
});
const AsyncTranslationsComponent = Loadable({
  loader: () => import("../../routes/translations"),
  loading: () => <RctPageLoader />,
});
const AsyncManageAgenciesComponent = Loadable({
  loader: () => import("../../routes/agencies/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddAgencyComponent = Loadable({
  loader: () => import("../../routes/agencies/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyAgencyComponent = Loadable({
  loader: () => import("../../routes/agencies/modify"),
  loading: () => <RctPageLoader />,
});
const AsyncManageRolesComponent = Loadable({
  loader: () => import("../../routes/roles/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddRoleComponent = Loadable({
  loader: () => import("../../routes/roles/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyRoleComponent = Loadable({
  loader: () => import("../../routes/roles/modify"),
  loading: () => <RctPageLoader />,
});
const AsyncManageUsersComponent = Loadable({
  loader: () => import("../../routes/users/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddUserComponent = Loadable({
  loader: () => import("../../routes/users/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyUserComponent = Loadable({
  loader: () => import("../../routes/users/modify"),
  loading: () => <RctPageLoader />,
});
/* expenses */
const AsyncManageExpensesComponent = Loadable({
  loader: () => import("../../routes/expenses/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddExpenseComponent = Loadable({
  loader: () => import("../../routes/expenses/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyExpenseComponent = Loadable({
  loader: () => import("../../routes/expenses/modify"),
  loading: () => <RctPageLoader />,
});
/* whatsapp devices */
const AsyncManageWhatsAppDevicesComponent = Loadable({
  loader: () => import("../../routes/whatsapp-devices/view-all"),
  loading: () => <RctPageLoader />,
});
/* announcements */
const AsyncManageAnnouncementsComponent = Loadable({
  loader: () => import("../../routes/announcements/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddAnnouncementComponent = Loadable({
  loader: () => import("../../routes/announcements/create"),
  loading: () => <RctPageLoader />,
});
/* notifications */
const AsyncManageNotificationsComponent = Loadable({
  loader: () => import("../../routes/notifications/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddNotificationComponent = Loadable({
  loader: () => import("../../routes/notifications/create"),
  loading: () => <RctPageLoader />,
});
/*message requests */
const AsyncManageMessageRequestsComponent = Loadable({
  loader: () => import("../../routes/message-requests/view-all"),
  loading: () => <RctPageLoader />,
});
/* students */
const AsyncManageStudentsComponent = Loadable({
  loader: () => import("../../routes/students/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddStudentComponent = Loadable({
  loader: () => import("../../routes/students/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyStudentComponent = Loadable({
  loader: () => import("../../routes/students/modify"),
  loading: () => <RctPageLoader />,
});
/* universities */
const AsyncManageUniversitiesComponent = Loadable({
  loader: () => import("../../routes/universities/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncViewUniversityComponent = Loadable({
  loader: () => import("../../routes/universities/view"),
  loading: () => <RctPageLoader />,
});
const AsyncAddUniversityComponent = Loadable({
  loader: () => import("../../routes/universities/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyUniversityComponent = Loadable({
  loader: () => import("../../routes/universities/modify"),
  loading: () => <RctPageLoader />,
});
/* specializations */
const AsyncManageSpecializationsComponent = Loadable({
  loader: () => import("../../routes/specializations/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddSpecializationComponent = Loadable({
  loader: () => import("../../routes/specializations/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifySpecializationComponent = Loadable({
  loader: () => import("../../routes/specializations/modify"),
  loading: () => <RctPageLoader />,
});
/* applications */
const AsyncManageApplicationsComponent = Loadable({
  loader: () => import("../../routes/applications/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncViewApplicationComponent = Loadable({
  loader: () => import("../../routes/applications/view"),
  loading: () => <RctPageLoader />,
});
const AsyncAddApplicationComponent = Loadable({
  loader: () => import("../../routes/applications/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyApplicationComponent = Loadable({
  loader: () => import("../../routes/applications/modify"),
  loading: () => <RctPageLoader />,
});
const AsyncViewDeadlinesComponent = Loadable({
  loader: () => import("../../routes/applications/deadlines"),
  loading: () => <RctPageLoader />,
});
/* suppliers */
const AsyncManageSuppliersComponent = Loadable({
  loader: () => import("../../routes/suppliers/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddSupplierComponent = Loadable({
  loader: () => import("../../routes/suppliers/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifySupplierComponent = Loadable({
  loader: () => import("../../routes/suppliers/modify"),
  loading: () => <RctPageLoader />,
});
/* lead sources */
const AsyncManageLeadSourcesComponent = Loadable({
  loader: () => import("../../routes/lead-sources/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddLeadSourceComponent = Loadable({
  loader: () => import("../../routes/lead-sources/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyLeadSourceComponent = Loadable({
  loader: () => import("../../routes/lead-sources/modify"),
  loading: () => <RctPageLoader />,
});
/* departments */
const AsyncManageDepartmentsComponent = Loadable({
  loader: () => import("../../routes/departments/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddDepartmentComponent = Loadable({
  loader: () => import("../../routes/departments/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyDepartmentComponent = Loadable({
  loader: () => import("../../routes/departments/modify"),
  loading: () => <RctPageLoader />,
});
/* message templates */
const AsyncManageMessageTemplatesComponent = Loadable({
  loader: () => import("../../routes/message-templates/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddMessageTemplateComponent = Loadable({
  loader: () => import("../../routes/message-templates/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyMessageTemplateComponent = Loadable({
  loader: () => import("../../routes/message-templates/modify"),
  loading: () => <RctPageLoader />,
});
/* agreement profits */
const AsyncManageAgreementProfitsComponent = Loadable({
  loader: () => import("../../routes/agreement-profits/view-all"),
  loading: () => <RctPageLoader />,
});
/* released profits */
const AsyncManageReleasedProfitsComponent = Loadable({
  loader: () => import("../../routes/released-profits/view-all"),
  loading: () => <RctPageLoader />,
});

/* kanban */
const AsyncKanbanComponent = Loadable({
  loader: () => import("../../routes/kanban"),
  loading: () => <RctPageLoader />,
});
/* knowledgebase */
const AsyncManageKnowledgebasesComponent = Loadable({
  loader: () => import("../../routes/knowledgebase/view-all"),
  loading: () => <RctPageLoader />,
});
const AsyncAddKnowledgebaseItemComponent = Loadable({
  loader: () => import("../../routes/knowledgebase/create"),
  loading: () => <RctPageLoader />,
});
const AsyncModifyKnowledgebaseItemComponent = Loadable({
  loader: () => import("../../routes/knowledgebase/modify"),
  loading: () => <RctPageLoader />,
});

export {
  AsyncHomeComponent,
  AsyncTranslationsComponent,
  AsyncManageAgenciesComponent,
  AsyncAddAgencyComponent,
  AsyncModifyAgencyComponent,
  AsyncManageRolesComponent,
  AsyncAddRoleComponent,
  AsyncModifyRoleComponent,
  AsyncManageUsersComponent,
  AsyncAddUserComponent,
  AsyncModifyUserComponent,
  AsyncManageExpensesComponent,
  AsyncAddExpenseComponent,
  AsyncModifyExpenseComponent,
  /*notifications */
  AsyncManageNotificationsComponent,
  AsyncAddNotificationComponent,
  /* announcements */
  AsyncManageAnnouncementsComponent,
  AsyncAddAnnouncementComponent,
  AsyncManageMessageRequestsComponent,
  /* students */
  AsyncManageStudentsComponent,
  AsyncAddStudentComponent,
  AsyncModifyStudentComponent,
  /* universities */
  AsyncManageUniversitiesComponent,
  AsyncViewUniversityComponent,
  AsyncAddUniversityComponent,
  AsyncModifyUniversityComponent,
  /* specializations */
  AsyncManageSpecializationsComponent,
  AsyncAddSpecializationComponent,
  AsyncModifySpecializationComponent,
  /* suppliers */
  AsyncManageSuppliersComponent,
  AsyncAddSupplierComponent,
  AsyncModifySupplierComponent,
  /* applications */
  AsyncManageApplicationsComponent,
  AsyncViewApplicationComponent,
  AsyncAddApplicationComponent,
  AsyncModifyApplicationComponent,
  AsyncViewDeadlinesComponent,
  /* agreement profits */
  AsyncManageAgreementProfitsComponent,
  /* released profits */
  AsyncManageReleasedProfitsComponent,
  AsyncKanbanComponent,
  /* whatsapp devices */
  AsyncManageWhatsAppDevicesComponent,
  /* lead sources */
  AsyncManageLeadSourcesComponent,
  AsyncAddLeadSourceComponent,
  AsyncModifyLeadSourceComponent,
  /* dapartments */
  AsyncManageDepartmentsComponent,
  AsyncAddDepartmentComponent,
  AsyncModifyDepartmentComponent,
  /* message templates */
  AsyncManageMessageTemplatesComponent,
  AsyncAddMessageTemplateComponent,
  AsyncModifyMessageTemplateComponent,
  /* knowledgebase */
  AsyncManageKnowledgebasesComponent,
  AsyncAddKnowledgebaseItemComponent,
  AsyncModifyKnowledgebaseItemComponent,
};
