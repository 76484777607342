import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import StageSteps from "./components/stageSteps";
import LeadTabs from "./components/tabs";
import Tab1 from "./components/tab-1";
import Tab4 from "./components/tab-4";
import Tab3 from "./components/tab-3";
import Tab2 from "./components/tab-2";
import { Button } from "@material-ui/core";

const tabs = ["General", "Wazzup", "History", "Know Legebase"];

const EditLead = ({ currentLead, setCurrentLead, stages, props, refresh }) => {
  const [selectedStage, setSelectedStage] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDialog = () => {
    setIsOpen(false);
    setCurrentLead(undefined)
  };
  
  useEffect(()=>{
    if(currentLead){
      setIsOpen(true)
      setSelectedStage(currentLead.phaseid)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentLead])

  const tabContent = () => {
    switch (activeTab) {
      case 0:
        return Tab1();
      case 1:
        return Tab2();
      case 2:
        return Tab3();
      case 3:
        return Tab4();

      default:
        return Tab1();
    }
  };

  return (
    <div className={`sliding-dialog ${isOpen ? "open" : ""}`}>
      <div className="dialog-header">
        <h3>
          <div className="d-flex align-items-center gap-4">
            <Icon
              icon={"mdi:account-plus-outline"}
              fontSize={"35px"}
              color="white"
            />
            <span>Edit Lead</span>
          </div>
        </h3>
        <Icon
          className="close-btn"
          icon={"cuida:x-circle-outline"}
          fontSize={"2.8rem"}
          color="white"
          role="button"
          onClick={toggleDialog}
        />
      </div>
      <div className="dialog-content">
        <div className="my-4">
          <StageSteps
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            stages={stages}
            lead_id={currentLead?.id}
            props={props}
            refresh={refresh}
          />
        </div>
        <LeadTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}>
          <div
            className="bg-white p-4 rounded-2 my-4 d-flex flex-column justify-content-between"
            style={{ minHeight: "50vh" }}
          >
            {tabContent()}
            <div
              className="d-flex align-items-center justify-content-center gap-4"
              style={{ padding: "5px 12rem" }}
            >
              <Button
                variant="outlined"
                className="py-2 rounded-3"
                style={{
                  color: "#21827F",
                  borderColor: "#21827F",
                  width: "20rem",
                  fontSize: "15px",
                }}
                disabled={activeTab === 0}
                onClick={() => {
                  setActiveTab((tab) => {
                    return tab - 1;
                  });
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                className="text-white py-2 rounded-3"
                style={{
                  backgroundColor: "#ED4C16",
                  width: "20rem",
                  fontSize: "15px",
                }}
                onClick={() => {
                  if (activeTab < tabs.length - 1) {
                    setActiveTab((tab) => {
                      return tab + 1;
                    });
                  } else {
                    // Write create lead function here
                  }
                }}
              >
                {activeTab < tabs.length - 1 ? "Next" : "Save"}
              </Button>
            </div>
          </div>
        </LeadTabs>
      </div>
    </div>
  );
};

export default EditLead;
