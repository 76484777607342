import React, { useState } from "react";
import { Icon } from "@iconify/react";
import axios from "../../../axios";
import { GeneralHelper } from "../../../libs";

export default function StageSteps({
  selectedStage,
  setSelectedStage,
  lead_id,
  stages,
  refresh,
  props,
}) {
  const [hoveredStage, setHoveredStage] = useState(null);

  const updateLead = (new_phase_id) => {
    const { handleAppError } = props;
    axios
      .post(
        `leads/${lead_id}`,
        GeneralHelper.getFormDataFromModelObject(
          { phaseid: new_phase_id },
          true
        )
      )
      .then(() => {
        refresh();
      })
      .catch((error) => {
        handleAppError(error);
      });
  };

  return (
    <div className="stage-steps">
      {stages &&
        stages.map((stage) => (
          <div
            key={stage.id}
            style={{
              backgroundColor:
                stage.id <= (hoveredStage || selectedStage)
                  ? "#21827F"
                  : "#F3F2F2",
              color:
                stage.id <= (hoveredStage || selectedStage)
                  ? "#21827F"
                  : "#F3F2F2",
            }}
            className="stage-step"
            role="button"
            onClick={() => {
              setSelectedStage(stage.id);
              if (lead_id) {
                updateLead(stage.id);
              }
            }}
            onMouseEnter={() => {
              setHoveredStage(stage.id);
            }}
            onMouseLeave={() => {
              setHoveredStage(null);
            }}
          >
            <Icon
              icon={stage.icon}
              style={{
                color: stage.color,
              }}
            />
            <div style={{ color: stage.color }}>{stage.name}</div>
          </div>
        ))}
    </div>
  );
}
