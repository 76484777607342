import React, { useState, useRef, useEffect } from "react";
import { ChatMicrophoneIcon } from "../../../constants/SVGIcons";

const MicrophoneElement = ({ setFile = (file) => {} }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const timerRef = useRef(null);
  const RECORDING_MAX_DURATION = 240; // 4 minutes in seconds

  useEffect(() => {
    if (!audioStream) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setAudioStream(stream);
          const mediaRecorder = new MediaRecorder(stream);
          setMediaRecorder(mediaRecorder);
          let audio;

          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              audio = [event.data];
            }
          };

          mediaRecorder.onstop = (event) => {
            const b = new Blob(audio, { type: "audio/wav" });
            setAudioBlob(b);
            console.log("audioBlob", b);
          };
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [audioStream]);

  useEffect(() => {
    setFile(audioBlob);
  }, [audioBlob]);
  const handleToggleRecording = (event) => {
    event.preventDefault();
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    mediaRecorder.start();
    setIsRecording(true);
    setRecordingTime(0);
    setAudioBlob(null);
    timerRef.current = setInterval(() => {
      setRecordingTime((prevTime) => {
        if (prevTime >= RECORDING_MAX_DURATION - 1) {
          stopRecording();
          return RECORDING_MAX_DURATION;
        }
        return prevTime + 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    mediaRecorder.stop();
    setIsRecording(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <ChatMicrophoneIcon
        onClick={handleToggleRecording}
        className="clickable"
        style={{ marginLeft: "-2rem" }}
      >
        {isRecording ? (
          <>
            <span className={`mr-3 ${isRecording && "animate-pulse"}`}>●</span>{" "}
            Stop Recording
          </>
        ) : audioBlob ? (
          "Redo recording"
        ) : (
          "Start Recording"
        )}
      </ChatMicrophoneIcon>
      <div
        style={{
          position: "absolute",
          bottom: "0.63rem",
          left: "5.8rem",
          height: "2.1rem",
          background: "white",
        }}
        className="d-flex align-items-center align-content-center"
      >
        {isRecording && (
          <div>
            <span>Recording, Time: {formatTime(recordingTime)}</span>
          </div>
        )}
        {audioBlob && (
          <>
            <audio controls style={{ width: "15rem", height: "100%" }}>
              <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
            </audio>
          </>
        )}
      </div>
    </>
  );
};

export default MicrophoneElement;
