// SlidingDialog.js
import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Icon } from "@iconify/react";

const SlidingDialog = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDialog = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div>
      <Button
        variant="contained"
        fullWidth
        className="create-btn text-white py-3"
        color={"#ED4C16"}
        startIcon={<Icon icon={"mdi:plus-circle-outline"} />}
        onClick={toggleDialog}
      >
        Create Lead
      </Button>

      <div className={`sliding-dialog ${isOpen ? "open" : ""}`}>
        <div className="dialog-header">
          <h3>{title}</h3>
          <Icon className="close-btn" icon={"cuida:x-circle-outline"} fontSize={"2.8rem"} color="white" role="button"  onClick={toggleDialog}/>
        </div>
        <div className="dialog-content">{children}</div>
      </div>
    </div>
  );
};

export default SlidingDialog;
