import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
} from "../../actions";
import _ from "lodash";
import AppConfig from "../../constants/AppConfig";
import {
  DashboardApplicationsCountIcon,
  DashboardBestSellerEmployeeIcon,
  DashboardExpensesIcon,
  DashboardMissingDocumentsIcon,
  DashboardPendingPaymentsIcon,
  DashboardProfitsIcon,
  DashboardRegisteredMedicineStudentsIcon,
  DashboardRegisteredStudentsIcon,
  DashboardScholarshipsIcon,
  DashboardStatisticsIcon,
  DashboardMarketingExpensesIcon,
  DashboardEmployeesTotalCommissionIcon,
  DashboardTop5AgentsIcon,
  DashboardAvatarIcon,
  DashboardTop5UniversitiesIcon,
  DashboardAgentsProfitIcon,
  DashboardSystemAgencyProfitIcon,
  DashboardTop5UniversitiesSubIcon,
  InsightDurationDaily,
} from "../../constants";
import ReactApexChart from "react-apexcharts";
import { _getApplyNow } from "../../libs";
import axios from "../../axios";
import { ThreeDot } from "react-loading-indicators";
import { ApplicationsChartElement } from "./components";
import { Icon } from "@iconify/react/dist/iconify.js";
class Home extends Component {
  state = {
    top5Announcements: [],
    top5Universities: [],
    universitiesProfits: {
      labels: [
        window.sys_app_translate(
          this.props.user.is_root_agency ? "No Commissions" : "No Profits",
          this.props.user.lang
        ),
      ],
      values: [0],
    },
    //
    pendingPaymentApplicationsCount: "-",
    medicineRegisteredApplicationsCount: "-",
    missingDocumentsApplicationsCount: "-",
    registeredStudentsCount: "-",
    totalApplicationsCount: "-",
    totalMarketingExpenses: "-",
    totalAgentsProfits: "-",
    systemAgencyProfits: "-",
    top5AgenciesStudentsCount: [],
    bestSellerEmployee: {},
    periodicData: null,
    periodicDataSeries: null,
    periodicDataActiveChoice: InsightDurationDaily,
    periodicDataType: "total-applications",
  };
  componentDidMount() {
    this.initialize();
  }
  initialize() {
    this.getTop5Announcements();
    this.getTop5Universities();
    this.getUniversitiesProfits();

    //
    this.getPendingPaymentApplicationsCount();
    this.getMedicineRegisteredApplicationsCount();
    this.getMissingDocumentsApplicationsCount();
    this.getRegisteredStudentsCount();
    this.getTotalApplicationsCount();
    this.getTotalMarketingExpenses();
    this.getTotalAgentsProfits();
    this.getSystemAgencyProfits();
    this.getTop5AgenciesStudentsCount();
    this.getBestSellerEmployee();
    this.getPeriodicData();
  }
  async getTop5Universities() {
    const { lang } = this.props.user;
    const { handleAppError, setLoading } = this.props;
    try {
      const response = await axios.get("dashboard/get-top-universities");
      const items = [];

      response.data.data.forEach((item) => {
        items.push({
          icon: (
            <img
              src={AppConfig.storagePath + item.logo}
              style={{ maxHeight: "3rem" }}
            />
          ),
          title: item.name,
          info: <DashboardTop5UniversitiesSubIcon />,
        });
      });
      await this.setState({
        top5Universities: items,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getUniversitiesProfits() {
    const { lang } = this.props.user;
    const { handleAppError, setLoading } = this.props;
    try {
      const response = await axios.get("dashboard/get-universities-profits");
      const profits = {
        labels: [],
        values: [],
      };
      response.data.data.forEach((item) => {
        profits.labels.push(item.universityname);
        profits.values.push(item.totalprofit);
      });
      if (profits.labels.length !== 0) {
        await this.setState({
          universitiesProfits: profits,
        });
      }
    } catch (error) {
      handleAppError(error);
    }
  }
  async getTop5Announcements() {
    console.log("getting top 5 announcements ...");
    try {
      const response = await axios.get("dashboard/get-top-announcements");
      await this.setState({
        top5Announcements: response.data.data,
      });
      console.log(response);
    } catch (error) {
      handleAppError(error);
    }
  }

  /* single numbers */
  async getPendingPaymentApplicationsCount() {
    console.log("getting pending payment applications count ...");
    const { handleAppError } = this.props;
    try {
      const response = await axios.get(
        "dashboard/get-pending-payment-applications-count"
      );
      await this.setState({
        pendingPaymentApplicationsCount: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getMedicineRegisteredApplicationsCount() {
    console.log("getting medicine registered applications count ...");
    try {
      const response = await axios.get(
        "dashboard/get-medicine-registered-applications-count"
      );
      await this.setState({
        medicineRegisteredApplicationsCount: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getMissingDocumentsApplicationsCount() {
    console.log("getting missing documents applications count ...");
    try {
      const repsonse = await axios.get(
        "dashboard/get-missing-documents-applications-count"
      );
      await this.setState({
        missingDocumentsApplicationsCount: repsonse.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getRegisteredStudentsCount() {
    console.log("getting registered students count ...");
    try {
      const response = await axios.get(
        "dashboard/get-registered-students-count"
      );
      await this.setState({
        registeredStudentsCount: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getTotalApplicationsCount() {
    console.log("getting total applications count ...");
    try {
      const response = await axios.get(
        "dashboard/get-total-applications-count"
      );
      await this.setState({
        totalApplicationsCount: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getTotalMarketingExpenses() {
    console.log("getting total marketing expenses ...");
    try {
      const response = await axios.get(
        "dashboard/get-total-marketing-expenses"
      );
      await this.setState({
        totalMarketingExpenses: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getTotalAgentsProfits() {
    console.log("getting total agents profits ...");
    try {
      const response = await axios.get("dashboard/get-total-agents-profits");
      await this.setState({
        totalAgentsProfits: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getSystemAgencyProfits() {
    console.log("getting system agency profits ...");
    try {
      const response = await axios.get("dashboard/get-system-agency-profits");
      await this.setState({
        systemAgencyProfits: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  async getTop5AgenciesStudentsCount() {
    const { handleAppError } = this.props;
    try {
      const response = await axios.get(
        "dashboard/get-top-5-agencies-students-count"
      );
      await this.setState({
        top5AgenciesStudentsCount: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }

  async getBestSellerEmployee() {
    const { handleAppError } = this.props;
    try {
      const response = await axios.get("dashboard/get-best-seller-employee");
      await this.setState({
        bestSellerEmployee: response.data.data,
      });
    } catch (error) {
      handleAppError(error);
    }
  }

  async getPeriodicData(insightDuration = InsightDurationDaily) {
    const { handleAppError, setLoading } = this.props;
    const { periodicDataType } = this.state;
    try {
      console.log("getting periodic data ...");
      setLoading(true);
      const response = await axios.get("dashboard/get-periodic-data", {
        params: {
          datatype: periodicDataType,
          insightduration: insightDuration,
        },
      });
      const data = response.data.data;
      await this.setState({
        periodicData: null,
        periodicDataSeries: null,
      });
      await this.setState({
        periodicData: data[0],
        periodicDataSeries: data[1],
      });
      setLoading(false);
    } catch (error) {
      handleAppError(error);
    }
  }

  _getInfoPieceElement({ icon, title, subTitle = null, info }) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-12 col-sm-2 px-0 d-flex justify-content-center align-items-center py-3"
            style={{
              backgroundColor: AppConfig.themeColors.primary,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
            }}
          >
            {icon}
          </div>
          <div className="col-12 col-sm-10 px-0 ">
            <div
              className="container-fluid"
              style={{
                backgroundColor: "white",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <div className="row">
                <div className="col-12 py-3">
                  <h3
                    className="inter inter-medium text-center"
                    style={{
                      color: AppConfig.themeColors.primary,
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </h3>
                  {info !== "-" && subTitle && (
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        color: AppConfig.themeColors.primary,
                      }}
                    >
                      {/* <iconify-icon icon="noto:star"></iconify-icon> */}
                      <Icon icon={"noto:star"} style={{ fontSize: "1.8rem" }} />
                      {subTitle}
                    </h3>
                  )}
                  <h4
                    style={{
                      color: AppConfig.themeColors.secondary,
                      fontWeight: "bold",
                      maxHeight: "29px",
                    }}
                    className="text-center"
                  >
                    {info === "-" ? (
                      <ThreeDot
                        variant="brick-stack"
                        color="#21827f"
                        size="medium"
                        text=""
                        textColor=""
                      />
                    ) : (
                      <span>{info}</span>
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  _getSection({ icon, title, content, items, minHeight = "auto" }) {
    const { lang } = this.props.user;
    return (
      <>
        <div className="container-fluid">
          <div
            className="row py-2"
            style={{
              backgroundColor: AppConfig.themeColors.primary,
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            <div className="col-12 px-3 d-flex align-items-center">
              <div>{icon}</div>
              <div
                className="inter inter-medium mx-3"
                style={{
                  color: "white",
                  display: "inline",
                  fontSize: "1.55rem",
                }}
              >
                {window.sys_app_translate(title, lang)}
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12"
              style={{
                backgroundColor: "white",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                minHeight: minHeight,
              }}
            >
              {items &&
                items.map((item, index) => {
                  return (
                    <div
                      className="row"
                      key={"item" + Math.random() + index}
                      style={{
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        borderBottom:
                          "1px solid " + AppConfig.themeColors.greyLighten,
                      }}
                    >
                      <div className="col-1">
                        {typeof item.icon === "string" ? (
                          <img
                            style={{ height: "2rem" }}
                            src={AppConfig.storagePath + item.icon}
                          />
                        ) : (
                          item.icon
                        )}
                      </div>
                      <div
                        className="col-8 inter inter-regular"
                        style={{
                          fontSize: "1.44rem",
                          color: AppConfig.themeColors.grey,
                        }}
                      >
                        {window.sys_app_translate(item.title, lang)}
                      </div>
                      <div
                        className="col-3 inter inter-medium"
                        style={{
                          fontSize: "1.44rem",
                          fontWeight: "bold",
                          textAlign: "right",
                          color: AppConfig.themeColors.secondary,
                        }}
                      >
                        {item.info}
                      </div>
                    </div>
                  );
                })}
              {content}
              {(!items || items.length === 0) && !content && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDot
                    variant="brick-stack"
                    color="#21827f"
                    size="medium"
                    text=""
                    textColor=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  _getChartSection({ icon, title }) {
    const { lang } = this.props.user;
    return (
      <div className="container-fluid">
        <div
          className="row py-2"
          style={{
            backgroundColor: AppConfig.themeColors.primary,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <div className="col-12 px-3 d-flex align-items-center">
            <div>{icon}</div>
            <div
              className="inter inter-medium mx-3"
              style={{
                color: "white",
                display: "inline",
                fontSize: "1.55rem",
              }}
            >
              {window.sys_app_translate(title, lang)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6"></div>
        </div>
      </div>
    );
  }
  _getExpensesSection() {
    const { lang } = this.props.user;
    const { totalMarketingExpenses } = this.state;
    return this._getSection({
      icon: <DashboardExpensesIcon />,
      title: window.sys_app_translate("Expenses", lang),
      content: null,
      items: [
        {
          icon: <DashboardMarketingExpensesIcon />,
          title: window.sys_app_translate("Total Marketing Expenses", lang),
          info:
            totalMarketingExpenses === "-" ? (
              <ThreeDot
                variant="brick-stack"
                color="#21827f"
                size="medium"
                text=""
                textColor=""
              />
            ) : (
              `${totalMarketingExpenses} $`
            ),
        },
        {
          icon: <DashboardEmployeesTotalCommissionIcon />,
          title: window.sys_app_translate("Total Employees Commissions", lang),
          info: "0 $",
        },
      ],
    });
  }
  _getProfitsSection() {
    const { handleAppError } = this.props;
    const { totalAgentsProfits, systemAgencyProfits } = this.state;
    try {
      const { lang } = this.props.user;
      return this._getSection({
        icon: <DashboardExpensesIcon />,
        title: window.sys_app_translate("Profits", lang),
        content: null,
        items: [
          {
            icon: <DashboardAgentsProfitIcon />,
            title: window.sys_app_translate("Total Agents Profits", lang),
            info:
              totalAgentsProfits === "-" ? (
                <ThreeDot
                  variant="brick-stack"
                  color="#21827f"
                  size="medium"
                  text=""
                  textColor=""
                />
              ) : (
                `${totalAgentsProfits} $`
              ),
          },
          {
            icon: <DashboardSystemAgencyProfitIcon />,
            title: window.sys_app_translate(
              "Total System Agency Profits",
              lang
            ),
            info:
              systemAgencyProfits === "-" ? (
                <ThreeDot
                  variant="brick-stack"
                  color="#21827f"
                  size="medium"
                  text=""
                  textColor=""
                />
              ) : (
                `${systemAgencyProfits} $`
              ),
          },
        ],
      });
    } catch (error) {
      handleAppError(error);
    }
  }
  _getProfitsChartSection() {
    const { lang, is_root_agency } = this.props.user;
    const values = this.state.universitiesProfits.values;
    values.forEach((value, index) => {
      values[index] = parseFloat(value);
    });
    return is_root_agency
      ? this._getChart({
          icon: <DashboardProfitsIcon />,
          title: window.sys_app_translate(
            is_root_agency ? "Commission" : "Profit",
            lang
          ),
          data: [0],
          labels: [
            window.sys_app_translate(
              this.props.user.is_root_agency ? "No Commissions" : "No Profits",
              this.props.user.lang
            ),
          ],
        })
      : this._getChart({
          icon: <DashboardProfitsIcon />,
          title: window.sys_app_translate(
            is_root_agency ? "Commission" : "Profit",
            lang
          ),
          data: values,
          labels: this.state.universitiesProfits.labels,
        });
  }
  _getTop5UniversitiesSection() {
    const { lang } = this.props.user;
    return this._getSection({
      icon: <DashboardTop5UniversitiesIcon />,
      title: window.sys_app_translate("Top 5 Universities", lang),
      content: null,
      items: this.state.top5Universities,
      minHeight: "20rem",
    });
  }
  //rows
  getRow1InfoItems() {
    const { lang } = this.props.user;
    const {
      registeredStudentsCount,
      missingDocumentsApplicationsCount,
      pendingPaymentApplicationsCount,
      top5Universities,
      universitiesProfits,
      totalApplicationsCount,
    } = this.state;
    return [
      {
        icon: <DashboardRegisteredStudentsIcon />,
        title: window.sys_app_translate("Registered Students", lang),
        info: registeredStudentsCount,
      },
      {
        icon: <DashboardApplicationsCountIcon />,
        title: window.sys_app_translate("Applications", lang),
        info: totalApplicationsCount,
      },
      {
        icon: <DashboardMissingDocumentsIcon />,
        title: window.sys_app_translate("Missing Documents", lang),
        info: missingDocumentsApplicationsCount,
      },
      {
        icon: <DashboardPendingPaymentsIcon />,
        title: window.sys_app_translate("Pending Payments", lang),
        info: pendingPaymentApplicationsCount,
      },
    ];
  }
  getRow2InfoItems() {
    const { lang } = this.props.user;
    const { medicineRegisteredApplicationsCount, bestSellerEmployee } =
      this.state;
    return [
      {
        icon: <DashboardScholarshipsIcon />,
        title: window.sys_app_translate("Scholarships", lang),
        info: "0",
        fullwidth: false,
      },
      {
        icon: <DashboardRegisteredMedicineStudentsIcon />,
        title: window.sys_app_translate("Registered Medicine Students", lang),
        info: medicineRegisteredApplicationsCount,
        topMargin: "2rem",
        fullwidth: false,
      },
      {
        icon: <DashboardBestSellerEmployeeIcon />,
        title: window.sys_app_translate("Best Seller Employee", lang),
        subTitle: bestSellerEmployee.name
          ? bestSellerEmployee.name
          : window.sys_app_translate("None", lang),
        info: bestSellerEmployee.count ? bestSellerEmployee.count : "-",
        topMargin: "2rem",
        fullwidth: true,
      },
    ];
  }
  //agency related
  _getChart({ icon = null, title = "", data = [], labels = [] }) {
    const { lang } = this.props.user;
    return (
      <div
        className="container-fluid"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        <div
          className="row py-2"
          style={{
            backgroundColor: AppConfig.themeColors.primary,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <div className="col-12 px-3 d-flex align-items-center">
            <div>{icon}</div>
            <div
              className="inter inter-medium mx-3"
              style={{
                color: "white",
                display: "inline",
                fontSize: "1.55rem",
              }}
            >
              {window.sys_app_translate(title, lang)}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              backgroundColor: "white",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ReactApexChart
                    options={{
                      legend: {
                        show: true,
                        floating: false,
                        itemMargin: {
                          horizontal: 50,
                        },
                      },
                      chart: {
                        type: "donut",
                      },
                      stroke: {
                        width: 10,
                        curve: "stepline",
                      },
                      plotOptions: {
                        pie: {
                          offsetX: "0.1",
                          donut: {
                            background: "#F2F2F2",
                            size: "80",
                            labels: {
                              show: true,
                              name: {
                                show: false,
                              },
                              value: {
                                color: AppConfig.themeColors.secondary,
                                fontSize: "39px",
                                fontWeight: "bold",
                              },
                              total: {
                                show: true,
                                showAlways: true,
                                formatter: function (w) {
                                  // Calculate the total of all series data points
                                  return (
                                    w.globals.seriesTotals.reduce(
                                      (a, b) => a + b,
                                      0
                                    ) + " $"
                                  );
                                },
                              },
                            },
                          },
                        },
                      },
                      colors:
                        labels[0] === "No Profits Yet"
                          ? ["#F2F2F2"]
                          : [
                              "#ED4C16",
                              "#FFD112",
                              "#09B84A",
                              "#955CB7",
                              "#21827F",
                            ],
                      dataLabels: {
                        enabled: false,
                      },
                      labels: labels,
                    }}
                    series={data}
                    type="donut"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  _getAgencyProfitsSection() {
    const { lang } = this.props.user;
    return this._getSection({
      icon: <DashboardProfitsIcon />,
      title: window.sys_app_translate("Profits", lang),
    });
  }
  _getTopAgentsSection() {
    const { lang } = this.props.user;
    const items = [];
    if (this.state.top5AgenciesStudentsCount.length > 0) {
      this.state.top5AgenciesStudentsCount.forEach((item) => {
        items.push({
          icon: item.logo ? (
            <img
              src={AppConfig.storagePath + item.logo}
              style={{ height: "3rem" }}
            />
          ) : (
            <DashboardAvatarIcon />
          ),
          title: item.name,
          info: `${item.total} students`,
        });
      });
    }
    return this._getSection({
      icon: <DashboardTop5AgentsIcon />,
      title: window.sys_app_translate("Top 5 Agents", lang),
      content: null,
      items: items,
      minHeight: "20rem",
    });
  }
  _getAnnouncementsSection({
    icon = null,
    title = "Announcements",
    // announcements = [
    //   {
    //     logo: require("../../assets/universities-logos/aydin.png"),
    //     title: "0",
    //     description: "0",
    //     date: "22.04.2024",
    //   },
    // ],
  }) {
    const { lang } = this.props.user;
    return (
      <div className="container-fluid">
        <div
          className="row py-2"
          style={{
            backgroundColor: AppConfig.themeColors.primary,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <div className="col-12 px-3 d-flex align-items-center">
            <div>{icon}</div>
            <div
              className="inter inter-medium mx-3"
              style={{
                color: "white",
                display: "inline",
                fontSize: "1.55rem",
              }}
            >
              {window.sys_app_translate(title, lang)}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="container-fluid announcements-section"
            style={{ maxHeight: "500px", overflowY: "auto" }}
          >
            {this.state.top5Announcements.map((announcement, index) => {
              return (
                <div
                  className="row"
                  style={{
                    borderBottom:
                      "1px solid " + AppConfig.themeColors.greyLighten,
                  }}
                >
                  <div
                    className="container-fluid py-2"
                    style={{ backgroundColor: "white", borderRadius: "5px" }}
                  >
                    <div className="row">
                      <div className="col-2 col-sm-1 d-flex align-items-center">
                        <img
                          style={{ height: "7rem" }}
                          src={
                            AppConfig.storagePath + announcement.universitylogo
                          }
                        />
                      </div>
                      <div className="col-10 col-sm-11">
                        <div className="container-fluid">
                          <div className="row">
                            <div
                              className="col-11 inter inter-medium"
                              style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                              }}
                            >
                              {announcement.title.toUpperCase()}
                            </div>
                            <div
                              className="col-1 inter inter-medium"
                              style={{
                                color: AppConfig.themeColors.secondary,
                                fontWeight: "bold",
                                textAlign: "right",
                              }}
                            >
                              *
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 inter inter-regular">
                              {announcement.text}
                            </div>
                          </div>
                          <div
                            className="row inter inter-medium"
                            style={{ marginTop: "1rem" }}
                          >
                            <div className="col-12">
                              {announcement.publishdatetimevalue}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  //dashboard functions
  getSuperAdminDashboard() {
    const { lang } = this.props.user;
    const { periodicDataSeries, periodicData, periodicDataActiveChoice } =
      this.state;
    return (
      <>
        <div className="row">
          {this.getRow1InfoItems().map((item, index) => {
            return (
              <div className="col-6 col-sm-3 zoom-on-hover" key={index}>
                {this._getInfoPieceElement(item)}
              </div>
            );
          })}
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-12 col-sm-3 px-0">
            <div className="container-fluid">
              <div className="row">
                {this.getRow2InfoItems().map((item, index) => {
                  return (
                    <div
                      className={
                        item.fullwidth
                          ? "col-12 col-sm-12 row-top-margin zoom-on-hover"
                          : index === 0
                          ? "col-6 col-sm-12 zoom-on-hover"
                          : "col-6 col-sm-12 row-top-margin zoom-on-hover"
                      }
                      key={index}
                    >
                      {this._getInfoPieceElement(item)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-9" style={{ position: "relative" }}>
            {this._getSection({
              icon: <DashboardStatisticsIcon />,
              title: window.sys_app_translate("Statistics Section", lang),
              content: (
                <ApplicationsChartElement
                  data={periodicData}
                  series={periodicDataSeries}
                  lang={lang}
                  activeChoice={periodicDataActiveChoice}
                  setActiveChoice={async (choice) => {
                    await this.setState({
                      periodicDataActiveChoice: choice,
                    });
                    this.getPeriodicData(choice);
                  }}
                  selectDataType={async (type) => {
                    await this.setState({
                      periodicDataType: type,
                    });
                    await this.getPeriodicData(
                      this.state.periodicDataActiveChoice
                    );
                  }}
                />
              ),
              items: null,
              minHeight: "19.8rem",
            })}
          </div>
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-12 col-sm-6">{this._getExpensesSection()}</div>
          <div className="col-12 col-sm-6">{this._getProfitsSection()}</div>
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-12 col-sm-6">
            {this._getTop5UniversitiesSection()}
          </div>
          <div className="col-12 col-sm-6">{this._getTopAgentsSection()}</div>
        </div>
      </>
    );
  }
  getAdminDashboard() {
    const { lang } = this.props.user;
    return (
      <>
        <div className="row">
          {this.getRow1InfoItems().map((item, index) => {
            return (
              <div className="col-6 col-sm-3 zoom-on-hover" key={index}>
                {this._getInfoPieceElement(item)}
              </div>
            );
          })}
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-12 col-sm-6">
            {this._getProfitsChartSection()}
          </div>
          <div className="col-12 col-sm-6">
            {this._getTop5UniversitiesSection()}
          </div>
        </div>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-12">
            {this._getAnnouncementsSection({
              icon: null,
              title: window.sys_app_translate("Announcements", lang),
            })}
          </div>
        </div>
        {_getApplyNow(this.props)}
      </>
    );
  }
  render() {
    const { lang, is_root_agency, is_super_admin } = this.props.user;
    return (
      <>
        <Helmet>
          <title>{window.sys_app_translate("Dashboard", lang)}</title>
        </Helmet>
        {is_root_agency && is_super_admin && this.getSuperAdminDashboard()}
        {(!is_root_agency || (!is_super_admin && is_root_agency)) &&
          this.getAdminDashboard()}
      </>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord } = appReducer;
  return { user, formErrors, currentRecord };
};
export default connect(mapStateToProps, {
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
  setCurrentRecord,
})(Home);
