/**
 * App.js Layout Start Here
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
//import LinearProgress from '@material-ui/core/LinearProgress';
// rct theme provider
import RctThemeProvider from "./RctThemeProvider";

//Main App
import DefaultLayout from "./DefaultLayout";
// app signin
import AppSignIn from "./Signin";
// // async components
// import {
//   AsyncSessionLoginComponent,
//   AsyncSessionLockScreenComponent,
//   AsyncSessionForgotPasswordComponent,
//   AsyncSessionPage404Component,
//   AsyncSessionPage500Component,
//   AsyncTermsConditionComponent,
// } from "../components/AsyncComponent/AsyncComponent";
import { showMessage, logoutUser } from "../actions";
import { RTL } from "./providers/RTL";
import { SubdomainChecker } from "../libs";
import FixBrowser from "./FixBrowser";
import {
  PAgreementView,
  PApplicationsViewDeadlines,
  PSectionAgreement,
  PSectionApplications,
} from "../constants";
/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  firstLoad = true;
  render() {
    const { location, match, user } = this.props;
    console.log("reached App js ...");

    //check permisions
    let isPermitted = true, //general rule: permit all
      pathArray = location.pathname.split("/");
    if (user && pathArray[2] === "translations") {
      isPermitted = user.permissions["translations"]["modify"];
    }
    if (
      user &&
      pathArray[2] === "applications" &&
      pathArray[3] === "deadlines"
    ) {
      isPermitted =
        user.permissions[PSectionApplications][PApplicationsViewDeadlines];
    }
    if (user && pathArray[2] === "agreement") {
      if (
        user.permissions &&
        user.permissions[PSectionAgreement] &&
        user.permissions[PSectionAgreement][PAgreementView]
      ) {
        isPermitted = user.permissions[PSectionAgreement][PAgreementView];
      }
    }
    if (!isPermitted) {
      return <Redirect to={"/app" + "/home"} />;
    }
    if (location.pathname === "/") {
      if (!user.access_token) {
        return <Redirect to={"/signin"} />;
      } else {
        return <Redirect to={"/app" + "/home"} />;
      }
    }
    return (
      <RTL>
        <RctThemeProvider
          rtlLayout={["ar", "fa"].includes(this.props.user.lang) ? true : false}
        >
          <NotificationContainer />
          <InitialPath
            path={`${match.url}` + "app"}
            authUser={user ? user.access_token : null}
            component={DefaultLayout}
          />
          <Route path="/signin" component={AppSignIn} />
          <Route path="/Fix" component={FixBrowser} />
        </RctThemeProvider>
      </RTL>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser, appReducer }) => {
  const { message } = appReducer;
  const { user } = authUser;
  return { user, message };
};

export default connect(mapStateToProps, { showMessage, logoutUser })(App);
