import {
  SET_CURRENT_RECORD,
  SET_MESSAGE,
  SET_ERROR,
  SET_LOADING,
  SET_CONFIRMATION,
  HANDLE_APP_ERROR,
  SET_FORM_ERRORS,
} from "../actions/types";
import { NotificationManager } from "react-notifications";

const INIT_STATE = {
  currentRecord: null,
  message: null,
  error: null,
  loading: true,
  confirmation: null,
  formErrors: null,
  appError: null,
  formErrors: null,
  viewMode: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_RECORD:
      return {
        ...state,
        currentRecord: action.payload,
      };
    case SET_MESSAGE:
      switch (action.payload.type) {
        case "success":
          NotificationManager.success(
            action.payload.message,
            action.payload.title,
            4000
          );
          break;
        case "warning":
          NotificationManager.warning(
            action.payload.message,
            action.payload.title,
            4000
          );
          break;
        case "info":
          NotificationManager.info(
            action.payload.message,
            action.payload.title,
            4000
          );
          break;
        case "danger":
          NotificationManager.error(
            action.payload.message,
            action.payload.title,
            4000
          );
          break;
      }
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CONFIRMATION:
      return {
        ...state,
        confirmation: action.payload,
      };
    case HANDLE_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
      };
    default:
      return { ...state };
  }
};
