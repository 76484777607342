import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import EmojiPicker from "emoji-picker-react";
import { set, size } from "lodash";
import {
  ChatEmojiPickerIcon,
  ChatFileUploadIcon,
  ChatMicrophoneIcon,
} from "../../../constants/SVGIcons";
import MicrophoneElement from "./MicrophoneElement";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AppConfig } from "../../../constants";

export const MessagingBox = ({ sendMessage = () => {} }) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const inputRef = React.createRef();
  const fileRef = React.createRef();
  const hasFileStyle = file
    ? {
        border: "1px solid " + AppConfig.themeColors.primary,
        padding: "1px",
        borderRadius: "50%",
      }
    : {};
  return (
    <>
      <div
        style={{
          width: "100%",
          position: "absolute",
          bottom: 1,
          background: "white",
        }}
        className="py-2 px-2 d-flex align-items-center"
      >
        <ChatEmojiPickerIcon
          className="clickable mx-2"
          onClick={() => {
            setIsEmojiPickerOpen(!isEmojiPickerOpen);
          }}
        />
        <input
          type="file"
          ref={fileRef}
          style={{ display: "none" }}
          onChange={(e) => {
            if (e.target.files.length > 0) {
              console.log(e.target.files[0]);
              setFile(e.target.files[0]);
            }
          }}
        />
        <ChatFileUploadIcon
          className="mx-3 clickable"
          style={hasFileStyle}
          onClick={() => {
            fileRef.current.value = null;
            fileRef.current.click();
          }}
        />
        <input
          type="text"
          ref={inputRef}
          placeholder="Type a message"
          className="form-control chat-text-input"
          style={{ width: "75%", paddingRight: "2.5rem" }}
        />
        <MicrophoneElement
          setFile={(audioBlob) => {
            setFile(audioBlob);
          }}
        />
        <Icon
          icon="iconoir:send-solid"
          className="clickable mx-4"
          fontSize={"1.8rem"}
          onClick={() => {
            if (inputRef.current || file) {
              sendMessage(
                inputRef.current ? inputRef.current.value : "",
                file,
                false
              );
            }
            setAudioBlob(null);
            if (inputRef.current) {
              inputRef.current.value = "";
            }
            if (fileRef.current) {
              fileRef.current.value = null;
            }
          }}
        />
      </div>
      <div style={{ position: "absolute", bottom: "3.5rem" }}>
        <EmojiPicker
          open={isEmojiPickerOpen}
          onEmojiClick={(emojiObj) => {
            inputRef.current.value += emojiObj.emoji;
          }}
        />
      </div>
    </>
  );
};
