import React, { useCallback, useState } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { Icon } from "@iconify/react";
import moment from "moment";
import "../index.scss";
import { ListItemText, Menu, MenuItem } from "@material-ui/core";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

export default function MyCalendar({ events, setEvents }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentView, setCurrentView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [currentTask, setCurrentTask] = useState(null);

  // when click on task open menu and set current task to make action on it
  const handleClick = (task, event) => {
    setAnchorEl(event.currentTarget);
    setCurrentTask(task);
    console.log(task);
    console.log(event);
  };

  // close menu
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentTask(null);
  };

  const deleteTask = () => {
    setEvents((prev) => prev.filter((t) => t.id !== currentTask.id));
    handleClose();
  };
  const editTask = () => {
    const newTitle = window.prompt("update task Name", currentTask.title);
    if(newTitle){
        currentTask.title = newTitle
    }
    handleClose();
  };
  const navigateToThisDay = () => {
    setCurrentView("day");
    setDate(currentTask.start);
    handleClose();
  };

  // one click on slot (day) or time you can open popup to add new task in this date
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt("New Event Name");
      if (title) {
        setEvents((prev) => [
          ...prev,
          { id: prev.length + 1, start, end, title },
        ]);
      }
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback((task, e) => handleClick(task, e), []);

  const titleAccessor = useCallback(
    (event) => (
      <div className="d-flex gap-3">
        <span>#{event.id}</span> {event.title}
      </div>
    ),
    []
  );
  const handleNavigate = (date) => {
    // Update the date when navigating
    setDate(date);
  };
  const handleViewChange = (newView) => {
    setCurrentView(newView); // Update the current view on tab change
  };
  return (
    <div className="calendarClass">
      <Calendar
        defaultView={Views.MONTH}
        date={date}
        events={events}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        view={currentView}
        onView={handleViewChange}
        selectable
        titleAccessor={titleAccessor}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className="d-flex gap-2" onClick={deleteTask}>
          <Icon icon={"mdi:trash-can-outline"} />
          <ListItemText primary="Delete Task" />
        </MenuItem>
        <MenuItem className="d-flex gap-2" onClick={editTask}>
          <Icon icon={"fa6-regular:pen-to-square"} />
          <ListItemText primary="Edit Task" />
        </MenuItem>
        <MenuItem className="d-flex gap-2" onClick={navigateToThisDay}>
          <Icon icon={"material-symbols-light:arrows-more-up-rounded"} />
          <ListItemText primary="See More" />
        </MenuItem>
      </Menu>
    </div>
  );
}
