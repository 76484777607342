import { TextField } from "@material-ui/core";
import { Icon } from "@iconify/react";

export default function Tab1() {
  return (
    <div className="row gy-4">
      <div className="col-6">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"mdi:account-outline"} fontSize={"1.2rem"} />
              First Name
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"mdi:account-outline"} />,
          }}
        />
      </div>
      <div className="col-6">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"mdi:account-outline"} fontSize={"1.2rem"} />
              Last Name
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"mdi:account-outline"} />,
          }}
        />
      </div>
      <div className="col-12">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"ic:outline-email"} fontSize={"1.2rem"} />
              Email
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"ic:outline-email"} />,
          }}
        />
      </div>
      <div className="col-12">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"ic:outline-email"} fontSize={"1.2rem"} />
              Responsible User
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"ic:outline-email"} />,
          }}
        />
      </div>
      <div className="col-6">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"mdi:account-outline"} fontSize={"1.2rem"} />
              Phase
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"mdi:account-outline"} />,
          }}
        />
      </div>
      <div className="col-6">
        <TextField
          label={
            <div className="d-flex gap-2 align-items-center">
              <Icon icon={"mdi:account-outline"} fontSize={"1.2rem"} />
              Source
            </div>
          }
          type="text"
          variant="outlined"
          size="medium"
          InputProps={{
            startAdornment: <Icon icon={"mdi:account-outline"} />,
          }}
        />
      </div>
    </div>
  );
}
