/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";
import "./index.scss";
import Header from "./components/header";
import Stage from "./components/stage";
import EditLead from "./edit";
import ActionsBar from "./components/actionsBar";
import axios from "../../axios";
import { GeneralHelper, ITQModal } from "../../libs";

const Kanban = (props) => {
  const { handleAppError } = props;
  const [state, setState] = useState({
    showPhaseFormModal: false,
  });
  const [agents, setAgents] = useState([{ label: "Ali Mah", value: 1 }]);
  const [stagesData, setStagesData] = useState([]);
  const [currentLead, setCurrentLead] = useState();
  const [refreshCount, setRefreshCount] = useState(0);

  const [selectedLeads, setSelectedLeads] = useState([]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5.5,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  // Function to fetch leads by stage
  const fetchStages = async () => {
    try {
      const response = await axios.get("phases/get-all");
      setStagesData(response.data.data);
    } catch (error) {
      handleAppError(error);
    }
  };

  const refresh = () => {
    setRefreshCount((val) => val + 1);
  };

  useEffect(() => {
    fetchStages();
  }, [refreshCount]);

  const updateLead = async (lead_id, new_phase_id) => {
    await axios
      .post(
        `leads/${lead_id}`,
        GeneralHelper.getFormDataFromModelObject(
          { phaseid: new_phase_id },
          true
        )
      )
      .then(() => {
        refresh();
      })
      .catch((error) => {
        handleAppError(error);
      });
  };
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result; // draggableId is the lead id

    // If no destination, exit the function
    if (!destination) {
      return;
    }

    // If the lead is dropped in the same position, exit the function
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Reorganize leads after moving from one stage to another
    // const sourceStageIndex = stagesData.findIndex(
    //   (stage) => `stage-${stage.id}` === source.droppableId
    // );
    const destinationStageIndex = stagesData.findIndex(
      (stage) => `stage-${stage.id}` === destination.droppableId
    );

    // const sourceStage = stagesData[sourceStageIndex];
    const destinationStage = stagesData[destinationStageIndex];

    updateLead(draggableId, destinationStage.id);
  };
  //add phase related
  const getPhaseFormPopup = () => {
    const { showPhaseFormModal } = state;
    return (
      <ITQModal
        isOpen={showPhaseFormModal}
        isCenterd={true}
        cancelButtonText="x"
        cancelButtonClicked={() => {
          setState({
            ...state,
            showPhaseFormModal: false,
          });
        }}
        title={window.sys_app_translate("Phase Info", props.user.lang)}
        bodyContent={<></>}
        size="lg"
      />
    );
  };
  const addPhase = async (record) => {
    const { setLoading, showMessage, handleAppError } = props;
    try {
      setLoading(true);
      const response = await axios.post(
        "phases/create",
        GeneralHelper.getFormDataFromModelObject(record, false)
      );
      showMessage(response.data.message, "success");
      fetchStages();
      setState({
        ...state,
        showPhaseFormModal: false,
      });
    } catch (error) {
      handleAppError(error);
    }
  };
  return (
    <div className="kanban-con">
      <Header props={props} agents={agents} stages={stagesData} />
      <ActionsBar
        selectedLeads={selectedLeads}
        setSelectedLeads={setSelectedLeads}
        refresh={refresh}
        props={props}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          className="mt-4"
        >
          {stagesData &&
            stagesData.map((stage) => (
              <Droppable key={stage.id} droppableId={`stage-${stage.id}`}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="stage"
                  >
                    <Stage
                      addStage={() => {
                        setState({
                          ...state,
                          showPhaseFormModal: true,
                        });
                      }}
                      key={stage.id}
                      props={props}
                      stage={stage}
                      refreshCount={refreshCount}
                      refresh={refresh}
                      setCurrentLead={setCurrentLead}
                      selectedLeads={selectedLeads}
                      setSelectedLeads={setSelectedLeads}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
        </Carousel>
      </DragDropContext>
      <EditLead
        currentLead={currentLead}
        setCurrentLead={setCurrentLead}
        stages={stagesData}
        refresh={refresh}
        props={props}
      />
      {getPhaseFormPopup()}
    </div>
  );
};

const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord, loading } = appReducer;
  return { user, formErrors, currentRecord, loading };
};

export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(Kanban);
