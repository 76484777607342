import React from "react";
import AppConfig from "../../../constants/AppConfig";
import { ChatEmptyAvatar } from "../../../constants/SVGIcons";

export const ContactItem = ({
  contact,
  isActive = false,
  onClick = () => {},
  newMessagesCount = 0,
}) => {
  return (
    <div
      className="row py-1 my-1 clickable"
      onClick={onClick}
      style={{
        borderRadius: "5px",
        backgroundColor: isActive ? "#F3F2F2" : "white",
      }}
    >
      <div
        className="col-12 px-2 d-flex align-items-center"
        style={{ position: "relative" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-2 px-0 d-flex align-items-center">
              {contact.profilepicture && contact.profilepicture !== "-" && (
                <img style={{ height: "3rem" }} src={contact.profilepicture} />
              )}
              {(!contact.profilepicture || contact.profilepicture === "-") && (
                <ChatEmptyAvatar height={"2rem"} />
              )}
            </div>
            <div className="col-10 px-0">
              <div style={{ fontSize: "0.9rem", fontWeight: "bold" }} className="inter inter-medium">
                {contact.name}
              </div>
              <div style={{ fontSize: "0.8rem" }} className="inter inter-medium">
                {contact.phone ? "+" + contact.phone : null}
              </div>
            </div>
          </div>
        </div>
        {newMessagesCount > 0 && (
          <span
            style={{
              position: "absolute",
              right: "0.6rem",
              top: "0.15rem",
              fontSize: "0.7rem",
              backgroundColor: AppConfig.themeColors.danger,
              borderRadius: "50%",
              padding: "3px 7px",
              color: "white",
            }}
          >
            {newMessagesCount}
          </span>
        )}
      </div>
    </div>
  );
};
