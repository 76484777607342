export const AgreementType = {
  Commission: "Commission",
  Bonus: "Bonus",
  Scholarship: "Scholarship",
};

export const AgreementTypesForDropdown = [
  {
    value: AgreementType.Commission,
    label: "Commission",
  },
  {
    value: AgreementType.Bonus,
    label: "Bonus",
  },
  {
    value: AgreementType.Scholarship,
    label: "Scholarship",
  },
];
