import { Button } from "@material-ui/core";
import { Icon } from "@iconify/react";
import NavigationBar from "./navigationBar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../../axios";

export default function ActionsBar({
  selectedLeads,
  setSelectedLeads,
  props,
  refresh,
}) {
  const { handleAppError } = props;

  const removeLeads = async () => {
    await axios
      .post(`leads/bulk-delete-leads?leadsids=${selectedLeads}`)
      .then(() => {
        refresh();
        setSelectedLeads([]);
      })
      .catch((error) => {
        handleAppError(error);
      });
  };

  const tabs = [
    {
      title: "Delete",
      action: () => {
        // Delete selected leads
        confirmAlert({
          title: window.sys_app_translate("Confirm Deletion", props.user.lang),
          message: window.sys_app_translate(
            "Are you sure to delete these leads?",
            props.user.lang
          ),
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                removeLeads();
              },
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
      },
    },
    {
      title: "Add To Exceptions",
      action: () => {
        // Add  selected leads To Exceptions
      },
    },
    // {
    //   title: "Change Stage",
    //   action: () => {
    //     // Change Stage to selected leads
    //   },
    // },
  ];

  return (
    <div className="tabs my-3 d-flex gap-3">
      {selectedLeads.length > 0 && (
          <div
            className="py-2 px-4 text-white rounded-2 d-flex gap-3 align-items-center"
            style={{ backgroundColor: "#ED4C16", fontWeight: 500, fontSize: "20px" }}
          >
            <span className="pb-1">{selectedLeads.length} selected </span>
          <Icon
            icon={"carbon:close-filled"}
            role="button"
            onClick={() =>
              setSelectedLeads([])
            }
          />
          </div>
      )}
      <NavigationBar />
      {selectedLeads.length > 0 && (
        <div className="tabs-header d-flex align-content-center gap-5 bg-white px-4 rounded-2">
          {tabs.map((tab, index) => (
            <Button
              className="tab-t"
              key={index}
              onClick={() => {
                tab.action();
              }}
            >
              {tab.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
