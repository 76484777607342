import { useState } from "react";
import { Icon } from "@iconify/react";
import { Button, TextField } from "@material-ui/core";
import { GeneralHelper } from "../../../libs";
import axios from "../../../axios";

export default function QuickLeadCard({
  stage,
  setShowQuickLeadForm,
  props,
  refresh,
}) {
  const { handleAppError } = props;

  const [state, setState] = useState({
    quickname: "",
    phone: "",
  });

  const close = () => {
    setState({
      quickname: "",
      phone: "",
    });
    setShowQuickLeadForm(false);
  };

  const createLead = async () => {
    const data = {
      ...state,
      phaseid: stage.id,
    };
    await axios
      .post(
        "leads/create",
        GeneralHelper.getFormDataFromModelObject(data, false)
      )
      .then(() => {
        refresh();
        close();
      })
      .catch((error) => {
        handleAppError(error);
      });
  };

  return (
    <div className="leads-sec">
      <div className="lead-card">
        <div className="card-header">
          <div>
            <Icon icon={"mdi:account-plus-outline"} />
            Add Quick Lead
          </div>
        </div>
        <div className="card-body">
          <form
            noValidate
            autoComplete="off"
            className="d-flex flex-column gap-4"
          >
            <div>
              <label className="py-1" style={{ color: "#21827F" }}>
                Name Lead
              </label>
              <TextField
                placeholder={"Join Ros"}
                onChange={(e) => {
                  setState({
                    ...state,
                    quickname: e.target.value,
                  });
                }}
                variant="outlined"
                InputProps={{
                  className: "px-3 bg-white",
                  startAdornment: (
                    <Icon
                      fontSize={"1.5rem"}
                      color={"#21827F"}
                      icon={"mdi:account-outline"}
                    />
                  ),
                }}
              />
            </div>
            <div>
              <label className="py-1" style={{ color: "#21827F" }}>
                Phone Lead
              </label>
              <TextField
                placeholder={"05545555798"}
                onChange={(e) => {
                  setState({
                    ...state,
                    phone: e.target.value,
                  });
                }}
                type="tel"
                variant="outlined"
                InputProps={{
                  className: "px-3 bg-white",
                  startAdornment: (
                    <Icon
                      fontSize={"1.5rem"}
                      color={"#21827F"}
                      icon={"mdi:phone-outline"}
                    />
                  ),
                }}
              />
            </div>
            <div className="d-flex justify-content-around gap-4">
              <Button
                variant="contained"
                fullWidth
                className="text-white"
                style={{ backgroundColor: "#ED4C16" }}
                onClick={() => close()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                fullWidth
                className="text-white"
                style={{ backgroundColor: "#21827F" }}
                onClick={() => createLead()}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
