import { useState } from "react";
import { Icon } from "@iconify/react";
import SlidingDialog from "../components/slidingDialog";
import StageSteps from "../components/stageSteps";
import LeadTabs from "../components/tabs";
import Tab1 from "../components/tab-1";
import Tab4 from "../components/tab-4";
import Tab3 from "../components/tab-3";
import Tab2 from "../components/tab-2";
import { Button } from "@material-ui/core";

const tabs = ["General", "Wazzup", "History", "Know Legebase"];

const AddLead = ({stages}) => {
  const [selectedStage, setSelectedStage] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const tabContent = () => {
    switch (activeTab) {
      case 0:
        return Tab1();
      case 1:
        return Tab2();
      case 2:
        return Tab3();
      case 3:
        return Tab4();

      default:
        return Tab1();
    }
  };

  return (
    <SlidingDialog
      title={
        <div className="d-flex align-items-center gap-4">
          <Icon
            icon={"mdi:account-plus-outline"}
            fontSize={"35px"}
            color="white"
          />
          <span>New Lead</span>
        </div>
      }
      type="add"
    >
      <div className="my-4">
        <StageSteps
          selectedStage={selectedStage}
          setSelectedStage={setSelectedStage}
          stages={stages}
        />
      </div>
      <LeadTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}>
        <div
          className="bg-white p-4 rounded-2 my-4 d-flex flex-column justify-content-between"
          style={{ minHeight: "50vh" }}
        >
          {tabContent()}
          <div
            className="d-flex align-items-center justify-content-center gap-4"
            style={{ padding: "5px 12rem" }}
          >
            <Button
              variant="outlined"
              className="py-2 rounded-3"
              style={{
                color: "#21827F",
                borderColor: "#21827F",
                width: "20rem",
                fontSize: "15px",
              }}
              disabled={activeTab === 0}
              onClick={() => {
                setActiveTab((tab) => {
                  return tab - 1;
                });
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="text-white py-2 rounded-3"
              style={{
                backgroundColor: "#ED4C16",
                width: "20rem",
                fontSize: "15px",
              }}
              onClick={() => {
                if (activeTab < tabs.length - 1) {
                  setActiveTab((tab) => {
                    return tab + 1;
                  });
                } else {
                  // Write create lead function here
                }
              }}
            >
              {activeTab < tabs.length - 1 ? "Next" : "Save"}
            </Button>
          </div>
        </div>
      </LeadTabs>
    </SlidingDialog>
  );
};

export default AddLead;
