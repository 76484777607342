import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ITQModal } from "../../libs/ITQModal";

import {
  MessagingIcon,
  NotificationsIcon,
  MenuBurgerIcon,
  WalletIcon,
  LogoutIcon,
  PathJoinerIcon,
  AdWhatsappIcon,
  PSectionRoles,
  PRolesCreate,
  PRolesViewAll,
  ProfitBagIcon,
  AppSettings,
  PAgreementView,
  PSectionAgreement,
  PApplicationsViewDeadlines,
  PAnnouncementsCreate,
  PAnnouncementsViewAll,
  PSectionTranslations,
  PTranslationsModify,
  PSectionWhatsAppDevices,
  PWhatsappDevicesViewAll,
  PWhatsappDevicesCreate,
  PSectionLeadSources,
  PLeadSourcesViewAll,
  PSectionKnowledgeBase,
  PKnowledgebaseItemsViewAll,
  PKnowledgebaseItemsCreate,
  PSectionMessageTemplates,
  PMessageTemplatesViewAll,
} from "../../constants";

import { Button, LinearProgress } from "@material-ui/core";

import {
  logoutUser,
  handleAppError,
  setLoading,
  setAgencyInfo,
} from "../../actions";

import { ErrorBoundary } from "react-error-boundary";

import {
  LanguageSelector,
  ITQNotifications,
  OfflineStorageKeyAllowAutoRefresh,
} from "../../libs";
import { database } from "../../libs/estrylib/Firebase";
import Menu from "../Sidebar/components/Menu";
import ForFallBack from "../../libs/ForFallBack";
import AppConfig from "../../constants/AppConfig";
import Chatra from "@chatra/chatra";
import $ from "jquery";
import { OfflineStorage } from "../../libs/estrylib";
import {
  PAgenciesCreate,
  PAgenciesViewAll,
  PAgreementProfitsViewAll,
  PApplicationsCreate,
  PApplicationsViewAll,
  PExpensesCreate,
  PExpensesViewAll,
  PMessageRequestsCreate,
  PMessageRequestsViewAll,
  PReleasedProfitsViewAll,
  PSectionAgencies,
  PSectionAgreementProfits,
  PSectionAnnouncements,
  PSectionApplications,
  PSectionExpenses,
  PSectionMessageRequests,
  PSectionReleasedProfits,
  PSectionSpecializations,
  PSectionStudents,
  PSectionSuppliers,
  PSectionUniversities,
  PSectionUsers,
  PSpecializationsCreate,
  PSpecializationsViewAll,
  PStudentsCreate,
  PStudentsViewAll,
  PSuppliersCreate,
  PSuppliersViewAll,
  PUniversitiesCreate,
  PUniversitiesViewAll,
  PUsersCreate,
  PUsersViewAll,
} from "../../constants";

class MainApp extends Component {
  state = {
    activeMenuItemIndex: 1, //starts from 0
    menu: null,
    adminMenu: null,
    showSignalsPopup: false,
    sidebarMenuHeight: "500px",
  };
  pathNames = {
    "/app/home": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Dashboard", this.props.user.lang),
    ],
    "/app/kanban": [
      window.sys_app_translate("Kanban", this.props.user.lang),
      ">",
      window.sys_app_translate("Dashboard", this.props.user.lang),
    ],
    "/app/chat": [
      window.sys_app_translate("Kanban", this.props.user.lang),
      ">",
      window.sys_app_translate("Dashboard", this.props.user.lang),
    ],
    //released profits
    "/app/released-profits/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Released Profits", this.props.user.lang),
    ],
    //agreements
    // "/app/agreements/view-all": [
    //   window.sys_app_translate("Home", this.props.user.lang),
    //   ">",
    //   window.sys_app_translate("Agreement Terms", this.props.user.lang),
    // ],
    //scholarships
    "/app/agreements/scholarships": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Scholarships", this.props.user.lang),
    ],
    //agreements
    "/app/agreement": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Agreement", this.props.user.lang),
    ],
    //agreement profits
    "/app/agreement-profits/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Agreement Profits", this.props.user.lang),
    ],
    //users
    "/app/users/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add User", this.props.user.lang),
    ],
    "/app/users/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify User", this.props.user.lang),
    ],
    "/app/users/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Users List", this.props.user.lang),
    ],
    //roles
    "/app/roles/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Role", this.props.user.lang),
    ],
    "/app/roles/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Role", this.props.user.lang),
    ],
    "/app/roles/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Roles List", this.props.user.lang),
    ],
    //agencies
    "/app/agencies/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Agency", this.props.user.lang),
    ],
    "/app/agencies/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Agency", this.props.user.lang),
    ],
    "/app/agencies/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Agencies List", this.props.user.lang),
    ],
    //translations
    "/app/translations": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Translations", this.props.user.lang),
    ],
    //universities
    "/app/universities/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Universities", this.props.user.lang),
    ],
    "/app/universities/view": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("View University", this.props.user.lang),
    ],
    "/app/universities/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add University", this.props.user.lang),
    ],
    "/app/universities/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify University", this.props.user.lang),
    ],
    //students
    "/app/students/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Students", this.props.user.lang),
    ],
    "/app/students/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Student", this.props.user.lang),
    ],
    "/app/students/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Student", this.props.user.lang),
    ],
    //applications
    "/app/applications/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Applications", this.props.user.lang),
    ],
    "/app/applications/deadlines": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("DeadLines", this.props.user.lang),
    ],
    "/app/applications/view": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("View Application", this.props.user.lang),
    ],
    "/app/applications/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Apply Now", this.props.user.lang),
    ],
    "/app/applications/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Application", this.props.user.lang),
    ],
    //announcements
    "/app/announcements/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Announcements", this.props.user.lang),
    ],
    "/app/announcements/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Create Announcement", this.props.user.lang),
    ],
    "/app/announcements/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Announcement", this.props.user.lang),
    ],
    //expenses
    "/app/expenses/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Expenses", this.props.user.lang),
    ],
    "/app/expenses/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Expense", this.props.user.lang),
    ],
    "/app/expenses/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Expense", this.props.user.lang),
    ],
    //whatsapp devices
    "/app/whatsapp-devices/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("WhatsApp Devices", this.props.user.lang),
    ],
    //specializations
    "/app/specializations/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Programs", this.props.user.lang),
    ],
    "/app/specializations/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Specialization", this.props.user.lang),
    ],
    "/app/specializations/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Specialization", this.props.user.lang),
    ],
    //suppliers
    "/app/suppliers/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Suppliers", this.props.user.lang),
    ],
    "/app/suppliers/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Supplier", this.props.user.lang),
    ],
    "/app/suppliers/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Supplier", this.props.user.lang),
    ],
    //notifications
    "/app/notifications/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Notifications", this.props.user.lang),
    ],
    "/app/notifications/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Notification", this.props.user.lang),
    ],
    //options
    "/app/options": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Options", this.props.user.lang),
    ],
    //calendar
    "/app/calendar": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Calendar", this.props.user.lang),
    ],
    //messages
    "/app/messaging": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Messaging", this.props.user.lang),
    ],
    //message-requests
    "/app/message-requests/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Message Requests", this.props.user.lang),
    ],
    //lead-sources
    "/app/lead-sources/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Lead Sources", this.props.user.lang),
    ],
    "/app/lead-sources/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Lead Source", this.props.user.lang),
    ],
    "/app/lead-sources/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Lead Source", this.props.user.lang),
    ],
    //departments
    "/app/departments/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("View Departments", this.props.user.lang),
    ],
    "/app/departments/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Department", this.props.user.lang),
    ],
    "/app/departments/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Department", this.props.user.lang),
    ],
    //message-templates
    "/app/message-templates/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Message Templates", this.props.user.lang),
    ],
    "/app/message-templates/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Message Template", this.props.user.lang),
    ],
    "/app/message-templates/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Message Template", this.props.user.lang),
    ],
    //knowledgebase
    //message-templates
    "/app/knowledgebase/view-all": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Knowledgebase", this.props.user.lang),
    ],
    "/app/knowledgebase/create": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Add Item", this.props.user.lang),
    ],
    "/app/knowledgebase/modify": [
      window.sys_app_translate("Home", this.props.user.lang),
      ">",
      window.sys_app_translate("Modify Item", this.props.user.lang),
    ],
  };
  firebaseListener = null;
  signalsFirebaseListener = null;
  ref = null;
  signalsRef = null;

  componentDidMount() {
    // document.onkeypress = function () {};
    // document.onkeydown = function () {};
    let sidebarMenuHeight = window.innerHeight - 140;
    const indexObj = this;
    window.addEventListener("resize", function () {
      sidebarMenuHeight = window.innerHeight - 160;
      indexObj.setState({
        sidebarMenuHeight: sidebarMenuHeight + "px",
      });
    });

    window.burgerButtonClicked = this.burgerButtonClicked;
    window.toggleTableFilterMenu = this.toggleTableFilterMenu;
    // $(window).on("beforeunload", function (e) {
    //   e = e || window.event;
    //   var localStorageTime = localStorage.getItem("storagetime");
    //   if (localStorageTime != null && localStorageTime != undefined) {
    //     var currentTime = new Date().getTime(),
    //       timeDifference = currentTime - localStorageTime;

    //     if (timeDifference < 25) {
    //       //Browser Closed
    //       //  localStorage.removeItem('storagetime');
    //       this.localStorage.clear();
    //     } else {
    //       //Browser Tab Closed
    //       localStorage.setItem("storagetime", new Date().getTime());
    //       this.localStorage.clear();
    //     }
    //   } else {
    //     localStorage.setItem("storagetime", new Date().getTime());
    //   }
    // });
    const {
      lang,
      permissions,
      agency_id,
      user_id,
      name,
      live_chat_id,
      top_parent_agency_id,
      is_super_admin,
    } = this.props.user;
    this.setState({
      sidebarMenuHeight: sidebarMenuHeight + "px",
      menu: [
        {
          path: "/home",
          title: window.sys_app_translate("Home", lang),
          subMenu: null,
          icon: "home",
        },
        //specializations
        permissions[PSectionSpecializations][PSpecializationsViewAll]
          ? {
              path: "/specializations/view-all",
              title: window.sys_app_translate("Programs", lang),
              subMenu: null,
              icon: "specializations",
            }
          : null,
        //students
        permissions[PSectionStudents][PStudentsViewAll]
          ? {
              path: "/students/view-all",
              title: window.sys_app_translate("Students", lang),
              subMenu: null,
              icon: "students",
            }
          : null,
        //applications
        permissions[PSectionApplications][PApplicationsViewAll] &&
        permissions[PSectionApplications][PApplicationsCreate]
          ? {
              path: "applications",
              title: window.sys_app_translate("Applications", lang),
              subMenu: null,
              icon: "applications",
              subMenu: [
                permissions[PSectionApplications][PApplicationsCreate] && {
                  title: window.sys_app_translate("Apply Now", lang),
                  path: "/applications/create",
                },
                permissions[PSectionApplications][PApplicationsViewAll] && {
                  title: window.sys_app_translate("Applications List", lang),
                  path: "/applications/view-all",
                },
              ],
            }
          : null,
        //universities
        permissions[PSectionUniversities][PUniversitiesViewAll]
          ? {
              path: "universities",
              title: window.sys_app_translate("Universities", lang),
              subMenu: null,
              icon: "universities",
              subMenu: [
                permissions[PSectionUniversities][PUniversitiesCreate] && {
                  title: window.sys_app_translate("Add University", lang),
                  path: "/universities/create",
                },
                permissions[PSectionUniversities][PUniversitiesViewAll] && {
                  title: window.sys_app_translate("Universities List", lang),
                  path: "/universities/view-all",
                },
              ],
            }
          : null,
        //released-profits
        top_parent_agency_id === agency_id
          ? permissions[PSectionReleasedProfits][PReleasedProfitsViewAll]
            ? {
                path: "agreement-profits",
                title: window.sys_app_translate("Profits", lang),
                subMenu: null,
                icon: "agreement-profits",
                subMenu: [
                  permissions[PSectionReleasedProfits][
                    PReleasedProfitsViewAll
                  ] && {
                    title: window.sys_app_translate("Released Profits", lang),
                    path: "/released-profits/view-all",
                  },
                ],
              }
            : null
          : null,
        //agreement
        top_parent_agency_id === agency_id &&
        permissions[PSectionAgreement] &&
        permissions[PSectionAgreement][PAgreementView]
          ? {
              path: "/agreement",
              title: window.sys_app_translate("Agreement", lang),
              subMenu: null,
              icon: "specializations",
            }
          : null,
        //users
        permissions[PSectionUsers][PUsersViewAll] ||
        permissions[PSectionUsers][PUsersCreate]
          ? {
              path: "users",
              title: window.sys_app_translate("Users", lang),
              subMenu: null,
              icon: "users",
              subMenu: [
                permissions[PSectionUsers][PUsersCreate] && {
                  title: window.sys_app_translate("Add User", lang),
                  path: "/users/create",
                },
                permissions[PSectionUsers][PUsersViewAll] && {
                  title: window.sys_app_translate("Users List", lang),
                  path: "/users/view-all",
                },
              ],
            }
          : null,
        //agencies
        permissions[PSectionAgencies][PAgenciesViewAll] ||
        permissions[PSectionAgencies][PAgenciesCreate]
          ? {
              path: "agencies",
              title: window.sys_app_translate("Agencies", lang),
              subMenu: null,
              icon: "agencies",
              subMenu: [
                permissions[PSectionAgencies][PAgenciesCreate] && {
                  title: window.sys_app_translate("Add Agency", lang),
                  path: "/agencies/create",
                },
                permissions[PSectionAgencies][PAgenciesViewAll] && {
                  title: window.sys_app_translate("Agencies List", lang),
                  path: "/agencies/view-all",
                },
              ],
            }
          : null,
        {
          path: "/logout",
          title: window.sys_app_translate("Logout", lang),
          subMenu: null,
          icon: "logout",
        },
      ],
      adminMenu: [
        {
          path: "/home",
          title: window.sys_app_translate("Home", lang),
          subMenu: null,
          icon: "home",
        },
        //specializations
        permissions[PSectionSpecializations][PSpecializationsViewAll] && {
          path: "/specializations/view-all",
          title: window.sys_app_translate("Programs", lang),
          subMenu: null,
          icon: "specializations",
        },
        //students
        permissions[PSectionStudents][PStudentsViewAll] && {
          path: "/students/view-all",
          title: window.sys_app_translate("Students", lang),
          subMenu: null,
          icon: "students",
        },
        //applications
        {
          path: "/applications/view-all",
          title: window.sys_app_translate("Applications", lang),
          subMenu: null,
          icon: "applications",
        },
        //deadlines
        permissions[PSectionApplications] &&
          permissions[PSectionApplications][PApplicationsViewDeadlines] && {
            path: "/applications/deadlines",
            title: window.sys_app_translate("DeadLines", lang),
            subMenu: null,
            icon: "deadlines",
          },
        //universities
        permissions[PSectionStudents][PStudentsViewAll] && {
          path: "/universities/view-all",
          title: window.sys_app_translate("Universities", lang),
          subMenu: null,
          icon: "universities",
        },
        //announcements
        permissions[PSectionAnnouncements] &&
        permissions[PSectionAnnouncements][PAnnouncementsViewAll]
          ? {
              path: "/announcements/view-all",
              title: window.sys_app_translate("Announcements", lang),
              subMenu: null,
              icon: "announcements",
            }
          : null,
        //agreement-profits and released-profits
        permissions[PSectionAgreementProfits][PAgreementProfitsViewAll] ||
        permissions[PSectionReleasedProfits][PReleasedProfitsViewAll]
          ? {
              path: "agreement-profits",
              title: window.sys_app_translate("Agreement Profits", lang),
              subMenu: [
                permissions[PSectionReleasedProfits][
                  PReleasedProfitsViewAll
                ] && {
                  title: window.sys_app_translate("Released Profits", lang),
                  path: "/released-profits/view-all",
                },
                permissions[PSectionAgreementProfits][
                  PAgreementProfitsViewAll
                ] && {
                  title: window.sys_app_translate("Agreement Profits", lang),
                  path: "/agreement-profits/view-all",
                },
              ],
              icon: "agreement-profits",
            }
          : null,
        //agreement
        permissions[PSectionAgreement] &&
        permissions[PSectionAgreement][PAgreementView]
          ? {
              path: "/agreement",
              title: window.sys_app_translate("Agreement", lang),
              subMenu: null,
              icon: "specializations",
            }
          : null,
        //suppliers
        permissions[PSectionSuppliers][PSuppliersViewAll] && {
          path: "/suppliers/view-all",
          title: window.sys_app_translate("Suppliers", lang),
          subMenu: null,
          icon: "suppliers",
        },
        //users
        permissions[PSectionUsers][PUsersViewAll] && {
          path: "users",
          title: window.sys_app_translate("Users", lang),
          subMenu: null,
          icon: "users",
          subMenu: [
            permissions[PSectionUsers][PUsersCreate] && {
              title: window.sys_app_translate("Add User", lang),
              path: "/users/create",
            },
            permissions[PSectionUsers][PUsersViewAll] && {
              title: window.sys_app_translate("Users List", lang),
              path: "/users/view-all",
            },
            permissions[PSectionRoles][PRolesCreate] && {
              title: window.sys_app_translate("Add User Role", lang),
              path: "/roles/create",
            },
            permissions[PSectionRoles][PRolesViewAll] && {
              title: window.sys_app_translate("User Roles", lang),
              path: "/roles/view-all",
            },
          ],
        },
        //agencies
        //students
        permissions[PSectionAgencies][PAgenciesViewAll] && {
          path: "/agencies/view-all",
          title: window.sys_app_translate("Agencies", lang),
          subMenu: null,
          icon: "agencies",
        },
        //expenses
        permissions[PSectionExpenses] &&
        permissions[PSectionExpenses][PExpensesViewAll]
          ? {
              path: "expenses",
              title: window.sys_app_translate("Expenses", lang),
              icon: "expenses",
              subMenu: [
                permissions[PSectionExpenses][PExpensesCreate] && {
                  title: window.sys_app_translate("Add Expense", lang),
                  path: "/expenses/create",
                },
                permissions[PSectionExpenses][PExpensesViewAll] && {
                  title: window.sys_app_translate("Expenses List", lang),
                  path: "/expenses/view-all",
                },
              ],
            }
          : null,
        //translations
        permissions[PSectionTranslations] &&
        permissions[PSectionTranslations][PTranslationsModify]
          ? {
              path: "/translations",
              title: window.sys_app_translate("Translations", lang),
              subMenu: null,
              icon: "translations",
            }
          : null,
        //messages
        permissions[PSectionMessageRequests][PMessageRequestsViewAll] ||
        permissions[PSectionMessageRequests][PMessageRequestsCreate]
          ? {
              path: "message-requests",
              title: window.sys_app_translate("Message Requests", lang),
              subMenu: null,
              icon: "message-requests",
              subMenu: [
                permissions[PSectionMessageRequests][
                  PMessageRequestsCreate
                ] && {
                  title: window.sys_app_translate("Add Message Request", lang),
                  path: "/messaging",
                },
                permissions[PSectionMessageRequests][
                  PMessageRequestsViewAll
                ] && {
                  title: window.sys_app_translate(
                    "Message Requests List",
                    lang
                  ),
                  path: "/message-requests/view-all",
                },
              ],
            }
          : null,
        //kanban
        permissions[PSectionStudents][PStudentsViewAll] && {
          path: "/kanban",
          title: window.sys_app_translate("Kanban", lang),
          subMenu: null,
          icon: "kanban",
        },
        //lead-sources
        permissions[PSectionLeadSources] &&
        permissions[PSectionLeadSources][PLeadSourcesViewAll]
          ? {
              path: "lead-sources",
              title: window.sys_app_translate("Lead Sources", lang),
              subMenu: null,
              icon: "lead-sources",
              subMenu: [
                permissions[PSectionMessageRequests][
                  PMessageRequestsCreate
                ] && {
                  title: window.sys_app_translate("Add Lead Source", lang),
                  path: "/lead-sources/create",
                },
                permissions[PSectionMessageRequests][
                  PMessageRequestsViewAll
                ] && {
                  title: window.sys_app_translate("Lead Sources List", lang),
                  path: "/lead-sources/view-all",
                },
              ],
            }
          : null,
        //knowledgebase
        permissions[PSectionKnowledgeBase] &&
        permissions[PSectionKnowledgeBase][PKnowledgebaseItemsViewAll]
          ? {
              path: "knowledgebase",
              title: window.sys_app_translate("Knowledgebase", lang),
              subMenu: null,
              icon: "knowledgebase",
              subMenu: [
                permissions[PSectionKnowledgeBase][
                  PKnowledgebaseItemsCreate
                ] && {
                  title: window.sys_app_translate(
                    "Add Knowledgebase Item",
                    lang
                  ),
                  path: "/knowledgebase/create",
                },
                permissions[PSectionKnowledgeBase][
                  PKnowledgebaseItemsViewAll
                ] && {
                  title: window.sys_app_translate(
                    "Knowledgebase Items List",
                    lang
                  ),
                  path: "/knowledgebase/view-all",
                },
              ],
            }
          : null,
        //whatsapp devices
        permissions[PSectionWhatsAppDevices] &&
        permissions[PSectionWhatsAppDevices][PWhatsappDevicesViewAll]
          ? {
              path: "whatsapp-devices",
              title: window.sys_app_translate("WP Devices", lang),
              subMenu: null,
              icon: "whatsapp-devices",
              subMenu: [
                permissions[PSectionWhatsAppDevices][
                  PWhatsappDevicesViewAll
                ] && {
                  title: window.sys_app_translate(
                    "Whatsapp Devices List",
                    lang
                  ),
                  path: "/whatsapp-devices/view-all",
                },
              ],
            }
          : null,
        //message templates
        permissions[PSectionMessageTemplates] &&
        permissions[PSectionMessageTemplates][PMessageTemplatesViewAll]
          ? {
              path: "message-templates",
              title: window.sys_app_translate("Message Templates", lang),
              subMenu: null,
              icon: "message-templates",
              subMenu: [
                permissions[PSectionKnowledgeBase][
                  PKnowledgebaseItemsCreate
                ] && {
                  title: window.sys_app_translate("Add Message Template", lang),
                  path: "/message-templates/create",
                },
                permissions[PSectionKnowledgeBase][
                  PKnowledgebaseItemsViewAll
                ] && {
                  title: window.sys_app_translate(
                    "Message Templates List",
                    lang
                  ),
                  path: "/message-templates/view-all",
                },
              ],
            }
          : null,
        //options
        is_super_admin
          ? {
              path: "/options",
              title: window.sys_app_translate("Settings", lang),
              subMenu: null,
              icon: "settings",
            }
          : null,
        {
          path: "/logout",
          title: window.sys_app_translate("Logout", lang),
          subMenu: null,
          icon: "logout",
        },
      ],
    });
    if (!this.ref) {
      const ref = database.ref().child("angs").child(agency_id);
      ref.onDisconnect().set("none");
      this.ref = ref;
      const signalsRef = database.ref().child("signals").child(user_id);
      this.signalsRef = signalsRef;
      this.registerFirebaseEvent();
    }
    let config = {
      integration: {
        name: name,
      },
      setup: {
        clientId: live_chat_id,
      },
      ID: "kcBySkByW43aojKK9",
    };
    //Chatra("init", config);
  }
  componentWillUnmount() {
    this.unRegisterFirebaseEvent();
  }
  firebaseListenerFunction(snapshot) {
    console.log("agency related values changed ...");
    const { setAgencyInfo } = this.props;
    const recordValue = snapshot.val();
    if (this.props.user.is_super_admin) {
    }
    setAgencyInfo(recordValue);
  }
  signalsFirebaseListenerFunction(snapshot) {
    console.log("new signal reached ...");
    const signalType = snapshot.val().split(";")[0],
      { history } = this.props;
    if (signalType === "RolesUpdated") {
      this.setState({
        showSignalsPopup: true,
      });
    }
    if (signalType === "BrowserRefresh") {
    }
  }
  registerFirebaseEvent() {
    console.log("registering firebase event ...");
    this.firebaseListener = this.ref.on(
      "value",
      this.firebaseListenerFunction.bind(this)
    );
    this.signalsFirebaseListener = this.signalsRef.on(
      "child_changed",
      this.signalsFirebaseListenerFunction.bind(this)
    );
    this.props.setLoading(false);
  }
  unRegisterFirebaseEvent() {
    console.log("unregistering firebase event ...");
    this.firstLoad = true;
    this.ref.off("child_changed");
    this.signalsRef.off("child_changed");
  }
  renderPage() {
    const { pathname } = this.props.location,
      { children } = this.props;
    return <div className="container-fluid page-container">{children}</div>;
  }
  burgerButtonClicked(onlyIfClosed = false) {
    console.log("burger button clicked fired ...");
    document
      .getElementsByClassName("sidebar-container")[0]
      .classList.toggle("sidebar-container-show");
    if (window.innerWidth <= 576) {
      window.setTimeout(function () {
        $(window).click(function (e) {
          console.log("window clicked ...");
          if ($(".sidebar-container").hasClass("sidebar-container-show")) {
            console.log("hiding sidebar ...");
            const specifiedElement =
              document.getElementsByClassName("sidebar-container")[0];
            const isClickInside = specifiedElement.contains(e.target);
            if (!isClickInside) {
              $(".sidebar-container").removeClass("sidebar-container-show");
              $(window).unbind("click");
            }
          }
        });
      }, 300);
    } else {
      if (onlyIfClosed && !$(".sidebar-bg").hasClass("closed")) {
        return;
      }
      if (!$(".sidebar-bg").hasClass("closed")) {
        $(".menu-item-n .angle").removeClass("active");
        $(".menu-item-n .sub-menu").removeClass("show");
      }
      $(".sidebar-bg").toggleClass("closed");
      //remove classes
      $(".s-top-bar").toggleClass("offset-md-3");
      $(".s-top-bar").toggleClass("offset-lg-3");
      $(".s-top-bar").toggleClass("offset-xl-2");

      $(".s-main-content").toggleClass("offset-md-3");
      $(".s-main-content").toggleClass("offset-lg-3");
      $(".s-main-content").toggleClass("offset-xl-2");
      //add classes
      $(".s-top-bar").toggleClass("offset-md-2");
      $(".s-top-bar").toggleClass("offset-lg-1");
      $(".s-top-bar").toggleClass("offset-xl-1");

      $(".s-main-content").toggleClass("offset-md-2");
      $(".s-main-content").toggleClass("offset-lg-1");
      $(".s-main-content").toggleClass("offset-xl-1");
    }
  }
  toggleTableFilterMenu() {
    $("#itqan_table_filters_top_container").toggleClass("itqan-mobile-hide");
    $(".itqan-overlay").toggleClass("show");
    window.setTimeout(function () {
      $(window).click(function (e) {
        console.log("window clicked ...");
        if (
          !$("#itqan_table_filters_top_container").hasClass("itqan-mobile-hide")
        ) {
          console.log("hiding sidebar ...");
          const specifiedElement = document.getElementById(
            "itqan_table_filters_top_container"
          );
          const isClickInside = specifiedElement.contains(e.target);
          if (!isClickInside) {
            $("#itqan_table_filters_top_container").toggleClass(
              "itqan-mobile-hide"
            );
            $(".itqan-overlay").toggleClass("show");
            $(window).unbind("click");
          }
        }
      });
    }, 300);
  }
  renderHeader() {
    const {
      user_id,
      name,
      lang,
      is_root_agency,
      agency_id,
      top_parent_agency_id,
      agency_profit,
      agency_profit2,
      system_currency,
      system_currency2,
      agency_logo,
      root_agency_logo,
      profile_picture,
    } = this.props.user;
    const { history, handleAppError, setLoading } = this.props;
    //change html lang attribute
    document.documentElement.setAttribute("lang", lang);
    return (
      <div
        className="container-fluid header"
        style={{ position: "fixed", width: "100%", zIndex: "1000" }}
      >
        <div className="row justify-content-between">
          <div className="col-5 col-md-3 offset-md-3 col-lg-2 offset-lg-0 col-xl-2 offset-xl-0 d-flex align-items-center px-0 s-top-bar">
            <div className="col-10 text-center d-none d-sm-block">
              {!is_root_agency && (
                <img
                  src={
                    agency_logo
                      ? AppConfig.storagePath + agency_logo
                      : require("../../assets/education-logo.png")
                  }
                  alt="Education System Logo"
                  style={{ height: "4rem", cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("/app/home");
                  }}
                />
              )}
              {is_root_agency && (
                <img
                  alt="Education System Logo"
                  src={require("../../assets/education-logo.png")}
                  style={{ height: "4rem", cursor: "pointer" }}
                  onClick={() => {
                    this.props.history.replace("/app/home");
                  }}
                />
              )}
            </div>
            <div className="col-2 offset-1 offset-sm-0">
              <div
                className="burger-button-container"
                onClick={() => {
                  window.burgerButtonClicked();
                }}
              >
                <MenuBurgerIcon className="clickable" />
              </div>
            </div>
            <div className="col-9 d-flex">
              {/* {top_parent_agency_id === agency_id && (
                <span
                  onClick={() => {
                    history.push("/app/balance-requests/view-all");
                  }}
                  className="clickable"
                >
                  <ProfitBagIcon />{" "}
                  <b>{agency_profit + " " + system_currency}</b>
                  {" || "}
                  <b>{agency_profit2 + " " + system_currency2}</b>
                </span>
              )} */}
              <span
                className="px-3 inter inter-bold"
                style={{ fontSize: "1.5rem", color: "#686868" }}
              >
                {
                  this.pathNames[history.location.pathname][
                    this.pathNames[history.location.pathname].length - 1
                  ]
                }
              </span>
            </div>
          </div>

          <div className="col-7 col-md-6 col-lg-5 col-xl-4 d-flex justify-content-between header-right-section align-items-center">
            <LanguageSelector
              lang={lang}
              handleAppError={handleAppError}
              setLoading={setLoading}
            />
            {/* <div className="messages d-flex align-items-center">
              <MessagingIcon className="icon" />
            </div> */}
            <div className="notifications d-flex align-items-center">
              <ITQNotifications
                userId={user_id}
                agencyId={agency_id}
                lang={lang}
              />
            </div>
            <div className="header-user">
              <img
                className="profile-picture"
                src={
                  profile_picture
                    ? AppConfig.storagePath + profile_picture
                    : require("../../assets/default-profile-picture.png")
                }
              />
              <div className="name">{this.props.user.name}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSidebar() {
    const menu = this.props.user.is_root_agency
      ? this.state.adminMenu
      : this.state.menu;
    const { sidebarMenuHeight } = this.state;
    return (
      <div
        className="container-fluid sidebar-bg px-0 mx-0"
        style={{ position: "fixed" }}
      >
        <div className="row">
          <div className="col-12 px-0">
            <div className="logo-container d-flex justify-content-center">
              {/* <img
                src={
                  agency_logo
                    ? AppConfig.storagePath + agency_logo
                    : AppConfig.storagePath + root_agency_logo
                }
                alt="Education System Logo"
                className="sidebar-logo"
                onClick={() => {
                  history.push("/app/home");
                }}
                style={{ cursor: "pointer" }}
              /> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 d-flex justify-content-center sidebar-menu-container"
            style={{ height: sidebarMenuHeight }}
          >
            <div className="sidebar-menu">
              {/* {menu &&
                menu.map((menuItem, index) => {
                  return menuItem ? (
                    <MenuItemButton
                      path={"/app/" + menuItem.path}
                      key={"MenuItemButton" + index}
                      title={menuItem.title}
                      icon={menuItem.icon}
                      subMenu={menuItem.subMenu}
                      setLoading={this.props.setLoading}
                    />
                  ) : (
                    <></>
                  );
                })} */}
              {menu && (
                <Menu
                  menuItems={menu}
                  handler={() => {}}
                  logout={this.props.logoutUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  errorFallback(props) {
    return <ForFallBack {...props} />;
    // return (
    //   <div role="alert">
    //     <p>Something went wrong:</p>
    //     <pre>{error.message}</pre>
    //     <button onClick={resetErrorBoundary}>Try again</button>
    //   </div>
    // );
  }
  renderSignalsPopup() {
    const { showSignalsPopup } = this.state;
    const { lang } = this.props.user;
    return (
      <ITQModal
        isOpen={showSignalsPopup}
        viewMode={true}
        isCenterd={true}
        size="sm"
        title={window.sys_app_translate("Important Update!", lang)}
        bodyContent={
          <>
            <div className="text-center">
              {window.sys_app_translate(
                "Your user is affected by new changes, you must logout"
              )}
            </div>
            <div className="text-center" style={{ marginTop: "35px" }}>
              <Button
                variant="contained"
                className="sidebar-logout-btn"
                onClick={() => {
                  this.props.setLoading(true);
                  this.props.logoutUser();
                }}
              >
                <LogoutIcon
                  style={{
                    marginRight: "5px",
                    width: "10px",
                  }}
                />
                {window.sys_app_translate("Logout", lang)}
              </Button>
            </div>
          </>
        }
        showFooter={true}
      />
    );
  }
  render() {
    const { loading, history } = this.props;
    console.log("rendering content ...");
    let lockLoadingPage = false;
    switch (history.location.pathname) {
      case "/app/insurance-requests/create":
      //case "/app/insurance-requests/view-all":
      case "/app/expenses/view-all":
      case "/app/announcements/view-all":
      case "/app/expenses/create":
        lockLoadingPage = false;
        break;
    }
    return (
      <ErrorBoundary FallbackComponent={this.errorFallback} onReset={() => {}}>
        {loading && (
          <div
            className="container-fluid"
            style={{ position: "fixed", zIndex: "100000" }}
          >
            <LinearProgress color="primary" />
            <LinearProgress color="primary" />
          </div>
        )}
        <div className="container-fluid" style={{ position: "relative" }}>
          {loading && lockLoadingPage ? (
            <div
              style={{
                background: "white",
                position: "absolute",
                opacity: "0.4",
                width: "100%",
                height: "100vh",
                zIndex: "9998",
              }}
            ></div>
          ) : null}
          <div className="row">
            <div className="col-sm-12 px-0 sidebar-container">
              {this.renderSidebar()}
            </div>
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 px-0">{this.renderHeader()}</div>
              </div>
            </div>
            <div className="col-12 col-md-8 offset-md-3 col-lg-8 offset-lg-3 col-xl-10 offset-xl-2 px-0 zoom-out-main s-main-content">
              <div className="container-fluid">
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "8.6rem" }}
                >
                  <div className="col-11 px-0">
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          {this.renderPage()}
                          {this.renderSignalsPopup()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade itqan-overlay"></div>
      </ErrorBoundary>
    );
  }
}

//map state to props
const mapStateToProps = ({ settings, authUser, appReducer }) => {
  const { loading } = appReducer;
  const { user } = authUser;
  return { user, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    handleAppError,
    setLoading,
    setAgencyInfo,
  })(MainApp)
);
