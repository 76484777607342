import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setLoading,
  showMessage,
  setCurrentRecord,
  handleAppError,
  clearFormErrors,
} from "../../actions";
import MyCalendar from "./components/calendar";
import Header from "../kanban/components/header";
import "./index.scss"
import { _events } from "./data";
import NavigationBar from "./components/navigationBar";

const Calendar = (props) => {
  const [agents, setAgents] = useState([{ label: "Ali Mah", value: 1 }]);
  const [events, setEvents] = useState(_events)
  return (
    <div className="calendar-con">
      <Header props={props} agents={agents} />
      <NavigationBar />
      <MyCalendar events={events} setEvents={setEvents} />
    </div>
  );
};

const mapStateToProps = ({ authUser, appReducer }) => {
  const { user } = authUser;
  const { formErrors, currentRecord, loading } = appReducer;
  return { user, formErrors, currentRecord, loading };
};

export default connect(mapStateToProps, {
  setCurrentRecord,
  setLoading,
  showMessage,
  handleAppError,
  clearFormErrors,
})(Calendar);