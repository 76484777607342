import { Icon } from "@iconify/react";
import { Button } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "../../../axios";

export default function Lead({
  lead,
  color,
  setCurrentLead,
  props,
  refresh,
  selectedLeads,
  setSelectedLeads,
}) {
  const { handleAppError } = props;

  const isSelected = selectedLeads.includes(lead.id);

  const dateString = lead.created_at;

  // Split the date and time parts
  const [datePart, timePart] = dateString.split(" ");

  const checkboxItem = () => {
    if (selectedLeads.length <= 0) {
      return null;
    }
    if (isSelected) {
      return (
        <Icon
          icon={"ph:check-square-bold"}
          color="#3CCB00"
          role="button"
          onClick={() =>
            setSelectedLeads((arr) => arr.filter((id) => id !== lead.id))
          }
        />
      );
    } else {
      return (
        <Icon
          icon={"ph:square-bold"}
          role="button"
          onClick={() => setSelectedLeads((arr) => [...arr, lead.id])}
        />
      );
    }
  };

  const removeLead = async (lead_id) => {
    await axios
      .delete(`leads/${lead_id}`)
      .then(() => {
        refresh();
      })
      .catch((error) => {
        handleAppError(error);
      });
  };

  return (
    <div className="lead-card">
      <div className="card-header">
        <div style={{ fontWeight: "500" }}>#{lead.id}</div>
        <div className="card-header-action">
          <Icon
            icon={"prime:pen-to-square"}
            role="button"
            onClick={() => {
              setCurrentLead(lead);
            }}
          />
          <Icon
            icon={"iconoir:xmark-circle"}
            role="button"
            onClick={() => {
              confirmAlert({
                title: window.sys_app_translate(
                  "Confirm Deletion",
                  props.user.lang
                ),
                message: window.sys_app_translate(
                  "Are you sure to delete this lead?",
                  props.user.lang
                ),
                buttons: [
                  {
                    label: "Yes",
                    onClick: () => {
                      removeLead(lead.id);
                    },
                  },
                  {
                    label: "No",
                    onClick: () => {},
                  },
                ],
              });
            }}
          />
          {checkboxItem()}
        </div>
      </div>
      <div
        className="card-body"
        onClick={() =>
          setSelectedLeads((arr) => {
            if (!isSelected) {
              return [...arr, lead.id];
            }
            return arr.filter((id) => id !== lead.id);
          })
        }
        onDoubleClick={() => {
          setCurrentLead(lead);
        }}
        style={{
          borderLeftColor: color,
          borderLeftWidth: "6px",
          borderLeftStyle: "solid",
          borderRadius: "3px",
          backgroundColor: isSelected ? "#21827f83" : "unset"
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <Icon
              icon={"ic:round-account-circle"}
              color="#C9C9C9"
              fontSize={"40px"}
              style={{ marginLeft: "-5px" }}
            />
            <span style={{ color: "#21827F", fontWeight: "bold" }}>
              {lead.quickname}
            </span>
          </div>
          <div
            style={{ backgroundColor: "#ED4C16", borderRadius: "3px" }}
            className="px-2 py-1 text-white"
          >
            {lead.id}
          </div>
        </div>
        <div className="s-2">
          <Icon className="icon" icon={"mdi:phone-outline"} />
          <div>{lead.phone}</div>
        </div>
        <div className="s-2">
          <Icon className="icon" icon={"mdi:email-outline"} />
          <div>{lead.email}</div>
        </div>
        <div className="s-2">
          <Icon className="icon" icon={"tabler:social"} />
          <div className="d-flex gap-2 align-items-center">
            WhatsApp
            <a
              href={`https://api.whatsapp.com/send?phone=${lead.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon={"logos:whatsapp-icon"} />
            </a>
          </div>
        </div>
        <div className="s-2">
          <div className="s-2">
            <Icon className="icon" icon={"mdi:calendar-range"} />
            <div>{datePart}</div>
          </div>
          <div className="s-2">
            <Icon className="icon" icon={"mdi:clock-outline"} />
            <div>{timePart}</div>
          </div>
        </div>
        <div className="s-2" style={{ color: "#ED4C16" }}>
          <Icon className="icon" color="#ED4C16" icon={"la:user-tie-solid"} />
          <div style={{ fontWeight: 500 }}>{lead.createdby}</div>
        </div>
        <div
          className="d-flex align-items-center my-2 gap-1"
          style={{ marginRight: "-16px" }}
        >
          <span
            style={{
              backgroundColor: "#ABFC88",
              width: "100%",
              fontWeight: 500,
            }}
            className="px-3 pb-1"
          >
            {lead.priority}
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Button
            variant="contained"
            className="py-1 px-4"
            style={{ backgroundColor: "#21827F", color: "white" }}
            startIcon={
              <Icon icon={"mdi:checkbox-marked-circle-plus-outline"} />
            }
            onClick={() => {
              this.setState({});
            }}
          >
            Add Task
          </Button>
        </div>
      </div>
    </div>
  );
}
