export default function LeadTabs({ children, activeTab, setActiveTab, tabs }) {
  return (
    <div className="tabs">
      <div className="tabs-header d-flex align-content-center gap-5 bg-white p-4 rounded-2">
        {tabs.map((tab, index) => (
          <div className={index===activeTab?"tab-active tab-t":"tab-t"} key={index} role="button" onClick={() => {
            setActiveTab(index)
          }}>
            {tab}
          </div>
        ))}
      </div>
      <div>{children}</div>
    </div>
  );
}
