/**
 * Insurance Agencies Routes
 */
/* eslint-disable */
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

// async components
import {
  AsyncManageDepartmentsComponent,
  AsyncAddDepartmentComponent,
  AsyncModifyDepartmentComponent,
} from "../../components/AsyncComponent/AsyncComponent";

const Forms = ({ match }) => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/manage`} />
      <Route
        path={`${match.url}/view-all`}
        component={AsyncManageDepartmentsComponent}
      />
      <Route
        path={`${match.url}/create`}
        component={AsyncAddDepartmentComponent}
      />
      <Route
        path={`${match.url}/modify`}
        component={AsyncModifyDepartmentComponent}
      />
    </Switch>
  </React.Fragment>
);

export default Forms;
