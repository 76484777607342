import { useState, useRef } from "react";
import Select from "react-select";
import { AppConfig } from "../../../constants";
import { TextField } from "@material-ui/core";
import { Icon } from "@iconify/react";
import AddLead from "../create";

const Header = ({ props, agents, stages}) => {
  const { lang } = props.user;
  const [state, setState] = useState({});
  const searchRef = useRef(null);
  return (
    <div className="row d-flex justify-content-space-between align-items-center">
      <div className="col-1" style={{width: "fit-content"}}>
        <Icon
          fontSize={"80px"}
          color={AppConfig.themeColors.white}
          icon={"ic:round-account-circle"}
        />
      </div>
      <div className="col-3">
        <Select
          className=" itqan-react-select-field"
          options={agents}
          isMulti={false}
          value={{ label: "Ali Mah", value: 1 }}
          onChange={(e) => {
            if (e) {
              setState({
                ...state,
                agentId: e.value,
              });
            } else {
              setState({
                ...state,
                agentId: null,
              });
            }
          }}
          placeholder=""
        />
      </div>

      <div className="col-6">
        <TextField
          id="outlined-basic"
          inputRef={searchRef}
          placeholder={window.sys_app_translate("Search", lang)}
          onChange={(e) => {
            setState({
              ...state,
              searchneedle: e.target.value,
            });
          }}
          type="search"
          variant="outlined"
          InputProps={{
            className: "px-3 bg-white",
            startAdornment: (
              <Icon
                fontSize={"30px"}
                color={AppConfig.themeColors.greyLighten}
                icon={"heroicons:magnifying-glass-16-solid"}
              />
            ),
            endAdornment: (
              <Icon
                fontSize={"30px"}
                color={AppConfig.themeColors.primary}
                icon={"mdi:filter-outline"}
              />
            ),
          }}
        />
      </div>
      <div className="col-2">
        <AddLead stages={stages} />
      </div>
    </div>
  );
};

export default Header;
