import { NavLink } from "react-router-dom";

export default function NavigationBar() {
  const tabs = [
    {
      title: "Kanban",
      path: "/kanban",
      active: true,
    },
    {
      title: "Calendar",
      path: "/calendar",
    },
    {
      title: "List",
      path: "/calendar",
    },
  ];

  return (
    <div className="tabs-header d-flex align-content-center gap-5 bg-white py-2 px-4 rounded-2">
      {tabs.map((tab) => (
        <NavLink
          className={tab.active ? "tab-active tab-t" : "tab-t"}
          key={tab.path}
          to={"/app" + tab.path}
        >
          {tab.title}
        </NavLink>
      ))}
    </div>
  );
}
