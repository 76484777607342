/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Button, ListItemText } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Draggable } from "react-beautiful-dnd";
import Lead from "./lead";
import QuickLeadCard from "./quickLeadCard";
import axios from "../../../axios";

export default function Stage({
  addStage,
  stage,
  props,
  setCurrentLead,
  selectedLeads,
  refresh,
  setSelectedLeads,
  refreshCount,
}) {
  const [leads, setLeads] = useState();
  const { lang } = props.user;
  const { handleAppError } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showQuickLeadForm, setShowQuickLeadForm] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to fetch leads by stage
  const fetchLeadsByStage = async () => {
    try {
      const response = await axios.get(
        `leads/get-all?by=phaseid&byValue=${stage.id}`
      );
      setLeads(response.data.data);
    } catch (error) {
      handleAppError(error);
    }
  };

  useEffect(() => {
    fetchLeadsByStage();
  }, [stage.id, refreshCount]); // Fetch leads when the component mounts or the stage id changes

  // const showQuickLeadForm = () => {

  // }
  const updateStage = () => {
    // Update stage
  };
  const deleteStage = () => {
    // Delete stage
  };
  return (
    <>
      <div className="fixed-sec">
        <div className="stage-header" style={{ color: stage.color }}>
          <div
            className="d-flex gap-2 justify-content-start align-items-center"
            style={{ width: "100%" }}
          >
            <Icon icon={stage.icon} />
            <span>{stage.name}</span>
          </div>
          <div
            className="d-flex justify-content-end align-items-center"
            style={{ width: "50%" }}
          >
            <span
              style={{ backgroundColor: stage.color, borderRadius: "3px" }}
              className="px-2 py-1 text-white"
            >
              {leads && leads.length}
            </span>
            <Icon
              icon={"la:ellipsis-v"}
              fontSize={"2.2rem"}
              aria-controls="stage-menu-actions"
              aria-haspopup="true"
              color="grey"
              role="button"
              onClick={handleClick}
            />
            <Menu
              id="stage-menu-actions"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className="d-flex gap-2" onClick={addStage}>
                <Icon icon={"ri:add-fill"} />
                <ListItemText primary="Add" onClick={addStage} />
              </MenuItem>
              <MenuItem className="d-flex gap-2" onClick={updateStage}>
                <Icon icon={"flowbite:edit-outline"} />
                <ListItemText primary="Update" />
              </MenuItem>
              <MenuItem className="d-flex gap-2" onClick={deleteStage}>
                <Icon icon={"mdi:trash-can-outline"} />
                <ListItemText primary="Delete" />
              </MenuItem>
            </Menu>
          </div>
        </div>
        <Button
          variant="contained"
          fullWidth
          className="ql-btn py-2"
          startIcon={<Icon icon={"mdi:account-plus-outline"} />}
          onClick={() => {
            setShowQuickLeadForm(true);
          }}
        >
          {window.sys_app_translate("Quick Lead", lang)}
        </Button>
      </div>
      {showQuickLeadForm && (
        <QuickLeadCard
          stage={stage}
          setShowQuickLeadForm={setShowQuickLeadForm}
          props={props}
          refresh={refresh}
        />
      )}
      <div
        className="leads-sec"
        style={{ paddingTop: showQuickLeadForm ? "unset" : "85px" }}
      >
        {leads &&
          leads.map((lead, index) => (
            <Draggable key={lead.id} draggableId={`${lead.id}`} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  className={`lead-card ${
                    snapshot.isDragging ? "dragging-lead" : ""
                  }`}
                >
                  <Lead
                    props={props}
                    color={stage.color}
                    lead={lead}
                    refresh={refresh}
                    setCurrentLead={setCurrentLead}
                    selectedLeads={selectedLeads}
                    setSelectedLeads={setSelectedLeads}
                  />
                </div>
              )}
            </Draggable>
          ))}
      </div>
    </>
  );
}
