import React, { useState, useEffect, useRef } from "react";
import { ContactItem, MessageElement, MessagingBox } from "./chat-components";
import { ChatSearchIcon } from "../../constants/SVGIcons";
import { ChatMessageType } from "../../constants/ChatMessageType";
import Pusher from "pusher-js";
import axios from "../../axios";
import { GeneralHelper } from "../../libs";

const ChatComponent = ({
  currentUser,
  getContacts = async () => {},
  getChats = async () => {},
  getChat = (contactId, userId) => {},
  sendMessage = (message) => {},
  userId = null,
  lang,
}) => {
  const [activeContactIndex, setActiveContactIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [contacts, setContacts] = useState([]);
  const channel = useRef(null);
  const [messages, setMessages] = useState([]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const chatMessagesContainerRef = useRef(null);
  useEffect(() => {
    if (chatMessagesContainerRef.current) {
      chatMessagesContainerRef.current.scrollTop =
        chatMessagesContainerRef.current.scrollHeight;
    }
  }, [messages]);
  useEffect(() => {
    getChats(userId).then((contacts) => {
      setContacts(contacts);
      getChat(contacts[activeContactIndex].wpcontactid, userId).then(
        (messages) => {
          setMessages(messages);
        }
      );
    });
  }, []);

  useEffect(() => {
    const pusher = new Pusher("7d8abac7e03e907d0b3b", {
      cluster: "eu",
    });

    pusher.connection.bind("connected", () => console.log("connected"));
    pusher.connection.bind("connecting", () => console.log("connecting"));
    pusher.connection.bind("connecting_in", () => console.log("connecting_in"));
    pusher.connection.bind("disconnected", () => console.log("disconnected"));
    pusher.connection.bind("unavailable", () => console.log("unavailable"));
    pusher.connection.bind("error", (err) => console.log({ err }, "error"));
    pusher.connection.bind("failed", (err) => console.log({ err }, "failed"));
    channel.current = pusher.subscribe("whatsapp-in");
    channel.current.bind("new_message", (data) => {
      // Update messages state with new message
      setMessages((prevMessages) => [...prevMessages, data.message.message]);
    });

    return () => {
      console.log("unsubscribing from pusher");
      channel.current.unbind_all();
      channel.current.unsubscribe();
    };
  }, []);
  useEffect(() => {
    // getChat(contacts[activeContactIndex].id).then((messages) => {
    //   setMessages(messages);
    // });
  }, [contacts]);
  useEffect(() => {
    //subscribe to pusher
    //listen to new messages
    //update contacts messages
  }, [messages]);
  const getContactsList = () => {
    const filteredContacts = contacts.filter(
      (contact) =>
        contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contact.phone.includes(searchQuery.toLowerCase())
    );
    return (
      <>
        {/* contacts list */}
        <div className="row">
          <div
            className="col-12 px-1"
            style={{ height: "500px", overflowY: "auto" }}
          >
            <div className="container-fluid">
              {filteredContacts.map((contact, index) => {
                return (
                  <ContactItem
                    contact={contact}
                    isActive={index === activeContactIndex}
                    onClick={() => {
                      setActiveContactIndex(index);
                      getChat(contact.wpcontactid, userId).then((messages) => {
                        setMessages(messages);
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };
  const getMessagesList = () => {
    return (
      <>
        <div className="chat-messages-container" ref={chatMessagesContainerRef}>
          {messages.map((message, index) => {
            return (
              <MessageElement
                type={message.type}
                content={message.messagebody}
                from={message.from}
                isFromUser={message.isfromuser}
                attachment={message.attachment}
                attachmentType={message.attachmenttype}
                timestamp={message.timestamp}
              />
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="row" style={{ background: "white" }}>
        <div className="col-3 px-0">
          <div className="container-fluid">
            <div className="row chat-searchbox-row">
              <div className="col-12 d-flex align-items-center">
                <ChatSearchIcon
                  style={{ marginLeft: "0.5rem", position: "absolute" }}
                />
                <input
                  type="text"
                  className="form-control chat-search-input"
                  placeholder={window.sys_app_translate("Search", "en")}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {getContactsList()}
          </div>
        </div>
        <div className="col-9 px-0">
          <div className="row current-user-bar mb-1">
            <div className="col-12 px-0">
              <div className="container-fluid">
                <div
                  className="row py-2"
                  style={{
                    minHeight: "50px",
                  }}
                >
                  <div className="col-1">
                    <img
                      style={{ width: "3rem" }}
                      src={require("../../assets/chat-sample-contact-avatar.png")}
                    />
                  </div>
                  <div className="col-8 px-0">
                    <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      Hala Ahmad
                    </div>
                    <div className="">Position</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-0">
              <div className="container chat-content">
                {getMessagesList()}
                <MessagingBox
                  sendMessage={(
                    message,
                    file = null,
                    isVoiceRecording = false
                  ) => {
                    if (file) {
                      const formData = new FormData();
                      if (file instanceof Blob) {
                        formData.append("file", file, "recording.wav");
                      } else {
                        formData.append("file", file);
                      }

                      formData.append("userid", userId);
                      formData.append(
                        "contactid",
                        contacts[activeContactIndex].id
                      );
                      formData.append("type", "file");
                      formData.append("isreply", null);
                      formData.append("replyto", null);
                      formData.append("message", message);
                      formData.append("isvoicerecording", isVoiceRecording);
                      axios.post("whatsapp/send-message", formData);
                    } else {
                      axios.post(
                        "whatsapp/send-message",
                        GeneralHelper.getFormDataFromModelObject({
                          userid: userId,
                          contactid: contacts[activeContactIndex].id,
                          message,
                          type: "text",
                          relatedfile: file,
                          isreply: null,
                          replyto: null,
                          isvoicerecording: isVoiceRecording,
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
